import { initializeApp } from 'firebase/app'
import {
  getRemoteConfig,
  fetchAndActivate,
  getBoolean,
} from 'firebase/remote-config'

export default async function ({ app }, inject) {
  const firebaseConfig = {
    apiKey: app.$config.FIREBASE_API_KEY,
    authDomain: app.$config.FIREBASE_AUTH_DOMAIN,
    projectId: app.$config.FIREBASE_PROJECT_ID,
    storageBucket: app.$config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: app.$config.FIREBASE_MESSAGING_SENDER_ID,
    appId: app.$config.FIREBASE_APP_ID,
    measurementId: app.$config.FIREBASE_MEASUREMENT_ID,
  }

  const firebaseApp = initializeApp(firebaseConfig)
  const remoteConfig = getRemoteConfig(firebaseApp)

  // Configure Remote Config settings
  remoteConfig.settings = {
    minimumFetchIntervalMillis:
      app.$config.ENVIRONMENT !== 'production' ? 3600000 : 43200000, // Fetch interval DEV TEST STAGE (1 hour) PROD (12 hours)
  }

  remoteConfig.defaultConfig = {
    IS_V3_API_FLOW: false, // Default parameter values
  }

  // Inject Remote Config into the Nuxt context
  inject('remoteConfig', remoteConfig)
}
