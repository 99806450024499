
import { INCLUDE_FIELDS } from '@/constants'
import startObserverLoading from '@/mixins/startObserverLoading'
import { useMarketStore } from '@/stores/market'
import { mapState } from 'pinia'
import { useContext, ref } from '@nuxtjs/composition-api'
import { useCustomer } from '@/stores/customer'

export default {
  name: 'MarketLandingMegaDiscounts',
  mixins: [startObserverLoading],
  props: {
    isProduct: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const { $device } = useContext()
    const totalSlidesCount = ref(10)
    let slidePerPage = 0
    let keenSliderOptions = {}
    if ($device && $device.isMobile && !$device.isCrawler) {
      slidePerPage = 2
      keenSliderOptions = {
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    } else if ($device && $device.isTablet && !$device.isCrawler) {
      slidePerPage = 3
      keenSliderOptions = {
        rubberband: false,
        breakpoints: {
          '(max-width: 768px)': {
            slides: { perView: 2, spacing: 8 }, // Up to 768px
          },
          '(min-width: 769px) and (max-width: 1000px)': {
            slides: { perView: slidePerPage, spacing: 8 }, // From 769px to 1000px
          },
          '(min-width: 1001px)': {
            slides: { perView: slidePerPage + 1, spacing: 8 }, // Greater than 1000px
          },
        },
      }
    } else if ($device && $device.isDesktop && !$device.isCrawler) {
      slidePerPage = 5

      keenSliderOptions = {
        breakpoints: {
          '(orientation: portrait)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(orientation: portrait) and (min-width: 1024px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape) and (min-width: 1200px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    }
    marketStore.setInitialMegaDiscountPlaceholders(totalSlidesCount.value)
    return {
      marketStore,
      keenSliderOptions,
      totalSlidesCount,
      slidePerPage,
      customerStore,
    }
  },
  data() {
    return {
      totalItemsFetched: 0,
      loadedIndexes: new Set(),
      sliderVisible: false,
      windowWidth: 0,
      waitingToLoad: true,
    }
  },
  fetchOnServer: true,
  async fetch() {
    if (this.customerStore.isBot) {
      this.slidePerPage = this.totalSlidesCount
      const initialItems = await this.startLoadingComponentMegaDiscount()
      this.marketStore.setMegaDiscountProducts(initialItems)
      const nonPlaceholderItems = this.megaDiscountProducts.filter(
        item => !item.placeholder,
      )
      if (nonPlaceholderItems.length > 0) {
        this.sliderVisible = true
        this.waitingToLoad = false
      }
    }
  },
  computed: {
    ...mapState(useMarketStore, ['megaDiscountProducts']),
    hasProducts() {
      return !!this.megaDiscountProducts.length
    },
    categoryId() {
      return this.marketStore.product?.category_id || null
    },
  },
  mounted() {
    this.updateWindowDimensions()
    if (!this.$device.isCrawler) {
      this.startObserverLoading(this.$refs.MegaDiscounts, this.fetchInitial)
    }
  },
  methods: {
    updateWindowDimensions() {
      this.windowWidth = window.innerWidth
    },
    async fetchInitial() {
      if (!this.sliderVisible) {
        const initialItems = await this.startLoadingComponentMegaDiscount()

        this.marketStore.setMegaDiscountProducts(initialItems)
        const nonPlaceholderItems = this.megaDiscountProducts.filter(
          item => !item.placeholder,
        )
        if (nonPlaceholderItems.length > 0) {
          this.sliderVisible = true
          this.waitingToLoad = false
        }
      }
    },
    goToMegaDiscounts() {
      const params = { name: 'mega-discounts' }
      if (this.$route?.name?.includes('product-slug'))
        params.query = { category_id: this.marketStore.product?.category_id }
      return this.localePath(params)
    },
    itemsOnPage(totalItems, itemsPerPage, pageNumber) {
      // Calculate the starting item index for the requested page
      const startIndex = (pageNumber - 1) * itemsPerPage

      // Calculate the number of items from the start index to the total items
      const itemsOnPage = totalItems - startIndex

      // Return the number of items if it's within the items per page limit
      return itemsOnPage > itemsPerPage ? itemsPerPage : itemsOnPage
    },
    async handleSlideChange({ slideIndex, index }) {
      const slider = this.$refs.megaDiscountSlider

      // Filter out placeholder items
      const nonPlaceholderItems = this.megaDiscountProducts.filter(
        item => !item.placeholder,
      )

      // Exit if all slides are non-placeholder items
      if (nonPlaceholderItems.length === this.totalSlidesCount) return

      const activetItem = this.megaDiscountProducts[slideIndex]
      const indexLoaded = this.loadedIndexes.has(index)

      if (indexLoaded) {
        if (activetItem.placeholder) {
          await this.startLoadingComponentMegaDiscount(index)
          slider.updateSlider()
        }
        // Move the slider and set the active dot regardless of placeholder status
        slider.moveTo(slideIndex)
        slider.activeDot = index
        return
      }

      // Load the next item and update the slider
      await this.startLoadingComponentMegaDiscount(index + 1)
      slider.updateSlider()

      // Move the slider and set the active dot
      slider.moveTo(slideIndex)
      slider.activeDot = index
    },

    async startLoadingComponentMegaDiscount(page = 1) {
      let perPage = this.slidePerPage
      if (!this.$device.isCrawler) {
        if (this.$device.isTablet && this.windowWidth >= 1000) {
          perPage = 4
        } else if (
          this.$device.isDesktop &&
          this.windowWidth <= 1200 &&
          this.windowWidth >= 1000
        ) {
          perPage = 4
        } else if (this.$device.isDesktop && this.windowWidth <= 1000) {
          perPage = 3
        } else if (this.$device.isTablet && this.windowWidth <= 768) {
          perPage = 2
        }
      }
      const lastPage = Math.ceil(this.totalSlidesCount / perPage)
      let startIndex = (page - 1) * perPage
      let newProducts = []

      const fetchPageData = async pageNumber => {
        const options = { page: pageNumber, per_page: perPage }
        return this.marketStore.getMegaDiscountProducts(options)
      }

      const sliceNewProducts = (products, pageIndex) => {
        const end = this.itemsOnPage(this.totalSlidesCount, perPage, pageIndex)
        return products.slice(0, end)
      }

      if (page === lastPage && !this.$device.isCrawler) {
        const previousPage = page - 1

        if (this.totalItemsFetched < previousPage * perPage) {
          if (!this.loadedIndexes.has(previousPage - 1)) {
            const [prevPageResponse, currentPageResponse] = await Promise.all([
              fetchPageData(previousPage),
              fetchPageData(page),
            ])

            newProducts = [
              ...prevPageResponse.newProducts,
              ...sliceNewProducts(currentPageResponse.newProducts, page),
            ]

            this.loadedIndexes.add(previousPage - 1)
            this.loadedIndexes.add(previousPage)
            startIndex = (previousPage - 1) * perPage
          } else {
            const { newProducts: fetchedNewProducts } = await fetchPageData(
              page,
            )
            newProducts = sliceNewProducts(fetchedNewProducts, page)
            this.loadedIndexes.add(previousPage)
            startIndex = previousPage * perPage
          }
        } else {
          const { newProducts: fetchedNewProducts } = await fetchPageData(page)
          newProducts = sliceNewProducts(fetchedNewProducts, page)
          startIndex = this.totalItemsFetched
        }
      } else {
        const { newProducts: fetchedNewProducts, totalItems } =
          await fetchPageData(page)

        if (totalItems < this.totalSlidesCount) {
          this.totalSlidesCount = totalItems
        }

        newProducts = fetchedNewProducts
      }

      this.marketStore.updateMegaDiscountProducts({
        startIndex,
        items: newProducts,
        totalSlideCount: this.totalSlidesCount,
      })

      if (this.$refs.megaDiscountSlider) {
        this.$refs.megaDiscountSlider.updateSlider()
      }

      this.totalItemsFetched += newProducts.length
      this.loadedIndexes.add(page - 1)
      this.waitingToLoad = false
      return newProducts
    },
  },
}
