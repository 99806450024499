export const BannersCompaniesBanner1 = () => import('../../components/Banners/CompaniesBanner1.vue' /* webpackChunkName: "components/banners-companies-banner1" */).then(c => wrapFunctional(c.default || c))
export const BannersCompaniesBanner2 = () => import('../../components/Banners/CompaniesBanner2.vue' /* webpackChunkName: "components/banners-companies-banner2" */).then(c => wrapFunctional(c.default || c))
export const BannersCompaniesBanner3 = () => import('../../components/Banners/CompaniesBanner3.vue' /* webpackChunkName: "components/banners-companies-banner3" */).then(c => wrapFunctional(c.default || c))
export const BannersCompaniesBanner4 = () => import('../../components/Banners/CompaniesBanner4.vue' /* webpackChunkName: "components/banners-companies-banner4" */).then(c => wrapFunctional(c.default || c))
export const BannersMPBannerProduct = () => import('../../components/Banners/MPBannerProduct.vue' /* webpackChunkName: "components/banners-m-p-banner-product" */).then(c => wrapFunctional(c.default || c))
export const BannersMegaDiscountsBanners1 = () => import('../../components/Banners/MegaDiscountsBanners1.vue' /* webpackChunkName: "components/banners-mega-discounts-banners1" */).then(c => wrapFunctional(c.default || c))
export const BannersMegaDiscountsBanners2 = () => import('../../components/Banners/MegaDiscountsBanners2.vue' /* webpackChunkName: "components/banners-mega-discounts-banners2" */).then(c => wrapFunctional(c.default || c))
export const BannersMegaDiscountsBanners3 = () => import('../../components/Banners/MegaDiscountsBanners3.vue' /* webpackChunkName: "components/banners-mega-discounts-banners3" */).then(c => wrapFunctional(c.default || c))
export const BannersMegaDiscountsBanners4 = () => import('../../components/Banners/MegaDiscountsBanners4.vue' /* webpackChunkName: "components/banners-mega-discounts-banners4" */).then(c => wrapFunctional(c.default || c))
export const BannersMegaDiscountsBannersDesktop = () => import('../../components/Banners/MegaDiscountsBannersDesktop.vue' /* webpackChunkName: "components/banners-mega-discounts-banners-desktop" */).then(c => wrapFunctional(c.default || c))
export const BannersMegaDiscountsHeaderBanner = () => import('../../components/Banners/MegaDiscountsHeaderBanner.vue' /* webpackChunkName: "components/banners-mega-discounts-header-banner" */).then(c => wrapFunctional(c.default || c))
export const BannersProductsBannerDesktop = () => import('../../components/Banners/ProductsBannerDesktop.vue' /* webpackChunkName: "components/banners-products-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const BannersProduktsBanner1 = () => import('../../components/Banners/ProduktsBanner1.vue' /* webpackChunkName: "components/banners-produkts-banner1" */).then(c => wrapFunctional(c.default || c))
export const BannersProduktsBanner2 = () => import('../../components/Banners/ProduktsBanner2.vue' /* webpackChunkName: "components/banners-produkts-banner2" */).then(c => wrapFunctional(c.default || c))
export const BannersProduktsBanner3 = () => import('../../components/Banners/ProduktsBanner3.vue' /* webpackChunkName: "components/banners-produkts-banner3" */).then(c => wrapFunctional(c.default || c))
export const BannersProduktsBanner4 = () => import('../../components/Banners/ProduktsBanner4.vue' /* webpackChunkName: "components/banners-produkts-banner4" */).then(c => wrapFunctional(c.default || c))
export const BannersSailDayBanner1 = () => import('../../components/Banners/SailDayBanner1.vue' /* webpackChunkName: "components/banners-sail-day-banner1" */).then(c => wrapFunctional(c.default || c))
export const BannersSailDayBanner2 = () => import('../../components/Banners/SailDayBanner2.vue' /* webpackChunkName: "components/banners-sail-day-banner2" */).then(c => wrapFunctional(c.default || c))
export const BannersSailDayBanner3 = () => import('../../components/Banners/SailDayBanner3.vue' /* webpackChunkName: "components/banners-sail-day-banner3" */).then(c => wrapFunctional(c.default || c))
export const BannersSailDayBanner4 = () => import('../../components/Banners/SailDayBanner4.vue' /* webpackChunkName: "components/banners-sail-day-banner4" */).then(c => wrapFunctional(c.default || c))
export const BannersSailDayBannerDesktop = () => import('../../components/Banners/SailDayBannerDesktop.vue' /* webpackChunkName: "components/banners-sail-day-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const BrandsCategoriesFound = () => import('../../components/Brands/CategoriesFound.vue' /* webpackChunkName: "components/brands-categories-found" */).then(c => wrapFunctional(c.default || c))
export const BrandsCategoryFoundItem = () => import('../../components/Brands/CategoryFoundItem.vue' /* webpackChunkName: "components/brands-category-found-item" */).then(c => wrapFunctional(c.default || c))
export const BrandsMPBrandsTitle = () => import('../../components/Brands/MPBrandsTitle.vue' /* webpackChunkName: "components/brands-m-p-brands-title" */).then(c => wrapFunctional(c.default || c))
export const CatalogMenuCategoriesItem = () => import('../../components/CatalogMenu/CategoriesItem.vue' /* webpackChunkName: "components/catalog-menu-categories-item" */).then(c => wrapFunctional(c.default || c))
export const CatalogMenuCategoriesMenu = () => import('../../components/CatalogMenu/CategoriesMenu.vue' /* webpackChunkName: "components/catalog-menu-categories-menu" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAdditionalServicesCredit = () => import('../../components/Checkout/AdditionalServicesCredit.vue' /* webpackChunkName: "components/checkout-additional-services-credit" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCancelledOrderBox = () => import('../../components/Checkout/CancelledOrderBox.vue' /* webpackChunkName: "components/checkout-cancelled-order-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddressesSelect = () => import('../../components/Checkout/CheckoutAddressesSelect.vue' /* webpackChunkName: "components/checkout-addresses-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeader = () => import('../../components/Checkout/CheckoutHeader.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c))
export const CheckoutLineItems = () => import('../../components/Checkout/CheckoutLineItems.vue' /* webpackChunkName: "components/checkout-line-items" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalErrorLoanApplications = () => import('../../components/Checkout/CheckoutModalErrorLoanApplications.vue' /* webpackChunkName: "components/checkout-modal-error-loan-applications" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNonLoggedInform = () => import('../../components/Checkout/CheckoutNonLoggedInform.vue' /* webpackChunkName: "components/checkout-non-logged-inform" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPudoMapSlider = () => import('../../components/Checkout/CheckoutPudoMapSlider.vue' /* webpackChunkName: "components/checkout-pudo-map-slider" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPudoMapZoomSlider = () => import('../../components/Checkout/CheckoutPudoMapZoomSlider.vue' /* webpackChunkName: "components/checkout-pudo-map-zoom-slider" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSeller = () => import('../../components/Checkout/CheckoutSeller.vue' /* webpackChunkName: "components/checkout-seller" */).then(c => wrapFunctional(c.default || c))
export const CheckoutIndexCheckoutPage = () => import('../../components/Checkout/IndexCheckoutPage.vue' /* webpackChunkName: "components/checkout-index-checkout-page" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInstallmentPaymentDisclaimer = () => import('../../components/Checkout/InstallmentPaymentDisclaimer.vue' /* webpackChunkName: "components/checkout-installment-payment-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutLoading = () => import('../../components/Checkout/Loading.vue' /* webpackChunkName: "components/checkout-loading" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderedProducts = () => import('../../components/Checkout/OrderedProducts.vue' /* webpackChunkName: "components/checkout-ordered-products" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrders = () => import('../../components/Checkout/Orders.vue' /* webpackChunkName: "components/checkout-orders" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPriceBox = () => import('../../components/Checkout/PriceBox.vue' /* webpackChunkName: "components/checkout-price-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPudoMapSelect = () => import('../../components/Checkout/PudoMapSelect.vue' /* webpackChunkName: "components/checkout-pudo-map-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPudoMapSelectAzerpoct = () => import('../../components/Checkout/PudoMapSelectAzerpoct.vue' /* webpackChunkName: "components/checkout-pudo-map-select-azerpoct" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRecipientDetails = () => import('../../components/Checkout/RecipientDetails.vue' /* webpackChunkName: "components/checkout-recipient-details" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSavedAddressSelect = () => import('../../components/Checkout/SavedAddressSelect.vue' /* webpackChunkName: "components/checkout-saved-address-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectedMethod = () => import('../../components/Checkout/SelectedMethod.vue' /* webpackChunkName: "components/checkout-selected-method" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStatusOrderBox = () => import('../../components/Checkout/StatusOrderBox.vue' /* webpackChunkName: "components/checkout-status-order-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSubmitButton = () => import('../../components/Checkout/SubmitButton.vue' /* webpackChunkName: "components/checkout-submit-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTOS = () => import('../../components/Checkout/TOS.vue' /* webpackChunkName: "components/checkout-t-o-s" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTotalPrice = () => import('../../components/Checkout/TotalPrice.vue' /* webpackChunkName: "components/checkout-total-price" */).then(c => wrapFunctional(c.default || c))
export const CheckoutYandexAddress = () => import('../../components/Checkout/YandexAddress.vue' /* webpackChunkName: "components/checkout-yandex-address" */).then(c => wrapFunctional(c.default || c))
export const CommonContactMap = () => import('../../components/Common/ContactMap.vue' /* webpackChunkName: "components/common-contact-map" */).then(c => wrapFunctional(c.default || c))
export const CommonFooterMenu = () => import('../../components/Common/FooterMenu.vue' /* webpackChunkName: "components/common-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonMainOverlay = () => import('../../components/Common/MainOverlay.vue' /* webpackChunkName: "components/common-main-overlay" */).then(c => wrapFunctional(c.default || c))
export const CommonResolution = () => import('../../components/Common/Resolution.vue' /* webpackChunkName: "components/common-resolution" */).then(c => wrapFunctional(c.default || c))
export const CommonStatus = () => import('../../components/Common/Status.vue' /* webpackChunkName: "components/common-status" */).then(c => wrapFunctional(c.default || c))
export const CreditLineMakingLoanApplication = () => import('../../components/CreditLine/MakingLoanApplication.vue' /* webpackChunkName: "components/credit-line-making-loan-application" */).then(c => wrapFunctional(c.default || c))
export const ErrorFiveHundred = () => import('../../components/Error/FiveHundred.vue' /* webpackChunkName: "components/error-five-hundred" */).then(c => wrapFunctional(c.default || c))
export const ErrorFourHundredFour = () => import('../../components/Error/FourHundredFour.vue' /* webpackChunkName: "components/error-four-hundred-four" */).then(c => wrapFunctional(c.default || c))
export const FaqPudoAzerpoct = () => import('../../components/Faq/PudoAzerpoct.vue' /* webpackChunkName: "components/faq-pudo-azerpoct" */).then(c => wrapFunctional(c.default || c))
export const FaqPudoAzerpoctCitiesAlphabet = () => import('../../components/Faq/PudoAzerpoctCitiesAlphabet.vue' /* webpackChunkName: "components/faq-pudo-azerpoct-cities-alphabet" */).then(c => wrapFunctional(c.default || c))
export const FooterMenuList = () => import('../../components/Footer/MenuList.vue' /* webpackChunkName: "components/footer-menu-list" */).then(c => wrapFunctional(c.default || c))
export const FooterSlideUpDownBlock = () => import('../../components/Footer/SlideUpDownBlock.vue' /* webpackChunkName: "components/footer-slide-up-down-block" */).then(c => wrapFunctional(c.default || c))
export const FooterTabBar = () => import('../../components/Footer/TabBar.vue' /* webpackChunkName: "components/footer-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const FooterTapBarItem = () => import('../../components/Footer/TapBarItem.vue' /* webpackChunkName: "components/footer-tap-bar-item" */).then(c => wrapFunctional(c.default || c))
export const FooterTopFooter = () => import('../../components/Footer/TopFooter.vue' /* webpackChunkName: "components/footer-top-footer" */).then(c => wrapFunctional(c.default || c))
export const HeaderAppHeader = () => import('../../components/Header/AppHeader.vue' /* webpackChunkName: "components/header-app-header" */).then(c => wrapFunctional(c.default || c))
export const HeaderBannerBonusBank = () => import('../../components/Header/BannerBonusBank.vue' /* webpackChunkName: "components/header-banner-bonus-bank" */).then(c => wrapFunctional(c.default || c))
export const HeaderCategories = () => import('../../components/Header/Categories.vue' /* webpackChunkName: "components/header-categories" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/Header/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/Header/Logo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderMiddleHeader = () => import('../../components/Header/MiddleHeader.vue' /* webpackChunkName: "components/header-middle-header" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileLogoRow = () => import('../../components/Header/MobileLogoRow.vue' /* webpackChunkName: "components/header-mobile-logo-row" */).then(c => wrapFunctional(c.default || c))
export const HeaderProductCategories = () => import('../../components/Header/ProductCategories.vue' /* webpackChunkName: "components/header-product-categories" */).then(c => wrapFunctional(c.default || c))
export const HeaderTopHeader = () => import('../../components/Header/TopHeader.vue' /* webpackChunkName: "components/header-top-header" */).then(c => wrapFunctional(c.default || c))
export const LandingAdultPopup = () => import('../../components/Landing/AdultPopup.vue' /* webpackChunkName: "components/landing-adult-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingAvailablePopup = () => import('../../components/Landing/AvailablePopup.vue' /* webpackChunkName: "components/landing-available-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingBasketNotAvailablePopup = () => import('../../components/Landing/BasketNotAvailablePopup.vue' /* webpackChunkName: "components/landing-basket-not-available-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingBonusInfoBlock = () => import('../../components/Landing/BonusInfoBlock.vue' /* webpackChunkName: "components/landing-bonus-info-block" */).then(c => wrapFunctional(c.default || c))
export const LandingCategoriesBlock = () => import('../../components/Landing/CategoriesBlock.vue' /* webpackChunkName: "components/landing-categories-block" */).then(c => wrapFunctional(c.default || c))
export const LandingCheckoutAvailablePopup = () => import('../../components/Landing/CheckoutAvailablePopup.vue' /* webpackChunkName: "components/landing-checkout-available-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingCheckoutWarningPopup = () => import('../../components/Landing/CheckoutWarningPopup.vue' /* webpackChunkName: "components/landing-checkout-warning-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingFaqBlock = () => import('../../components/Landing/FaqBlock.vue' /* webpackChunkName: "components/landing-faq-block" */).then(c => wrapFunctional(c.default || c))
export const LandingFooterBlock = () => import('../../components/Landing/FooterBlock.vue' /* webpackChunkName: "components/landing-footer-block" */).then(c => wrapFunctional(c.default || c))
export const LandingGeneralInfoBlock = () => import('../../components/Landing/GeneralInfoBlock.vue' /* webpackChunkName: "components/landing-general-info-block" */).then(c => wrapFunctional(c.default || c))
export const LandingHeaderBlock = () => import('../../components/Landing/HeaderBlock.vue' /* webpackChunkName: "components/landing-header-block" */).then(c => wrapFunctional(c.default || c))
export const LandingHowItWorkPopup = () => import('../../components/Landing/HowItWorkPopup.vue' /* webpackChunkName: "components/landing-how-it-work-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingHowWorkBlock = () => import('../../components/Landing/HowWorkBlock.vue' /* webpackChunkName: "components/landing-how-work-block" */).then(c => wrapFunctional(c.default || c))
export const LandingLPartner = () => import('../../components/Landing/LPartner.vue' /* webpackChunkName: "components/landing-l-partner" */).then(c => wrapFunctional(c.default || c))
export const LandingMarketLinks = () => import('../../components/Landing/MarketLinks.vue' /* webpackChunkName: "components/landing-market-links" */).then(c => wrapFunctional(c.default || c))
export const LandingPartnersBlock = () => import('../../components/Landing/PartnersBlock.vue' /* webpackChunkName: "components/landing-partners-block" */).then(c => wrapFunctional(c.default || c))
export const LandingSearchClientPopup = () => import('../../components/Landing/SearchClientPopup.vue' /* webpackChunkName: "components/landing-search-client-popup" */).then(c => wrapFunctional(c.default || c))
export const LandingTopBtn = () => import('../../components/Landing/TopBtn.vue' /* webpackChunkName: "components/landing-top-btn" */).then(c => wrapFunctional(c.default || c))
export const LandingWhyUmico = () => import('../../components/Landing/WhyUmico.vue' /* webpackChunkName: "components/landing-why-umico" */).then(c => wrapFunctional(c.default || c))
export const LandingYoutubeBlock = () => import('../../components/Landing/YoutubeBlock.vue' /* webpackChunkName: "components/landing-youtube-block" */).then(c => wrapFunctional(c.default || c))
export const MapContactMap = () => import('../../components/Map/ContactMap.vue' /* webpackChunkName: "components/map-contact-map" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/Map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MapPudoMap = () => import('../../components/Map/PudoMap.vue' /* webpackChunkName: "components/map-pudo-map" */).then(c => wrapFunctional(c.default || c))
export const MapPudoMapPintInfo = () => import('../../components/Map/PudoMapPintInfo.vue' /* webpackChunkName: "components/map-pudo-map-pint-info" */).then(c => wrapFunctional(c.default || c))
export const MarketAboutLoanCreditSteps = () => import('../../components/Market/AboutLoanCreditSteps.vue' /* webpackChunkName: "components/market-about-loan-credit-steps" */).then(c => wrapFunctional(c.default || c))
export const MarketAddToCart = () => import('../../components/Market/AddToCart.vue' /* webpackChunkName: "components/market-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const MarketBestPriceBadge = () => import('../../components/Market/BestPriceBadge.vue' /* webpackChunkName: "components/market-best-price-badge" */).then(c => wrapFunctional(c.default || c))
export const MarketCashbackDukan = () => import('../../components/Market/CashbackDukan.vue' /* webpackChunkName: "components/market-cashback-dukan" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutCreditBtn = () => import('../../components/Market/CheckoutCreditBtn.vue' /* webpackChunkName: "components/market-checkout-credit-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketCheckoutOtherUserBtn = () => import('../../components/Market/CheckoutOtherUserBtn.vue' /* webpackChunkName: "components/market-checkout-other-user-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketCreditBadge = () => import('../../components/Market/CreditBadge.vue' /* webpackChunkName: "components/market-credit-badge" */).then(c => wrapFunctional(c.default || c))
export const MarketDeliveryTwoHours = () => import('../../components/Market/DeliveryTwoHours.vue' /* webpackChunkName: "components/market-delivery-two-hours" */).then(c => wrapFunctional(c.default || c))
export const MarketDetailedCompanyRating = () => import('../../components/Market/DetailedCompanyRating.vue' /* webpackChunkName: "components/market-detailed-company-rating" */).then(c => wrapFunctional(c.default || c))
export const MarketFormReviewAnswer = () => import('../../components/Market/FormReviewAnswer.vue' /* webpackChunkName: "components/market-form-review-answer" */).then(c => wrapFunctional(c.default || c))
export const MarketFreeDeliveryBadge = () => import('../../components/Market/FreeDeliveryBadge.vue' /* webpackChunkName: "components/market-free-delivery-badge" */).then(c => wrapFunctional(c.default || c))
export const MarketImageMagnifier = () => import('../../components/Market/ImageMagnifier.vue' /* webpackChunkName: "components/market-image-magnifier" */).then(c => wrapFunctional(c.default || c))
export const MarketKapitalBankCallCenter = () => import('../../components/Market/KapitalBankCallCenter.vue' /* webpackChunkName: "components/market-kapital-bank-call-center" */).then(c => wrapFunctional(c.default || c))
export const MarketLoanCreditProcess = () => import('../../components/Market/LoanCreditProcess.vue' /* webpackChunkName: "components/market-loan-credit-process" */).then(c => wrapFunctional(c.default || c))
export const MarketLotteryFortuneWheel = () => import('../../components/Market/LotteryFortuneWheel.vue' /* webpackChunkName: "components/market-lottery-fortune-wheel" */).then(c => wrapFunctional(c.default || c))
export const MarketMPBestsellersSlider = () => import('../../components/Market/MPBestsellersSlider.vue' /* webpackChunkName: "components/market-m-p-bestsellers-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketMPBreadCrumbs = () => import('../../components/Market/MPBreadCrumbs.vue' /* webpackChunkName: "components/market-m-p-bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCategories = () => import('../../components/Market/MPCategories.vue' /* webpackChunkName: "components/market-m-p-categories" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCategoriesAdvertisingBlock = () => import('../../components/Market/MPCategoriesAdvertisingBlock.vue' /* webpackChunkName: "components/market-m-p-categories-advertising-block" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCategoriesSlider = () => import('../../components/Market/MPCategoriesSlider.vue' /* webpackChunkName: "components/market-m-p-categories-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCategory = () => import('../../components/Market/MPCategory.vue' /* webpackChunkName: "components/market-m-p-category" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCategorySEO = () => import('../../components/Market/MPCategorySEO.vue' /* webpackChunkName: "components/market-m-p-category-s-e-o" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCategorySeoQuestions = () => import('../../components/Market/MPCategorySeoQuestions.vue' /* webpackChunkName: "components/market-m-p-category-seo-questions" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCompanyReview = () => import('../../components/Market/MPCompanyReview.vue' /* webpackChunkName: "components/market-m-p-company-review" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCompanyReviewMessages = () => import('../../components/Market/MPCompanyReviewMessages.vue' /* webpackChunkName: "components/market-m-p-company-review-messages" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCompanyReviewQuestions = () => import('../../components/Market/MPCompanyReviewQuestions.vue' /* webpackChunkName: "components/market-m-p-company-review-questions" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCompanyReviewSuccess = () => import('../../components/Market/MPCompanyReviewSuccess.vue' /* webpackChunkName: "components/market-m-p-company-review-success" */).then(c => wrapFunctional(c.default || c))
export const MarketMPCompanyReviewTab = () => import('../../components/Market/MPCompanyReviewTab.vue' /* webpackChunkName: "components/market-m-p-company-review-tab" */).then(c => wrapFunctional(c.default || c))
export const MarketMPHeader = () => import('../../components/Market/MPHeader.vue' /* webpackChunkName: "components/market-m-p-header" */).then(c => wrapFunctional(c.default || c))
export const MarketMPHeaderTitleSkeleton = () => import('../../components/Market/MPHeaderTitleSkeleton.vue' /* webpackChunkName: "components/market-m-p-header-title-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MarketMPMegaDiscountsBreadcrumbs = () => import('../../components/Market/MPMegaDiscountsBreadcrumbs.vue' /* webpackChunkName: "components/market-m-p-mega-discounts-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const MarketMPModal = () => import('../../components/Market/MPModal.vue' /* webpackChunkName: "components/market-m-p-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketMPModalStatic = () => import('../../components/Market/MPModalStatic.vue' /* webpackChunkName: "components/market-m-p-modal-static" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPopularNow = () => import('../../components/Market/MPPopularNow.vue' /* webpackChunkName: "components/market-m-p-popular-now" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductContainer = () => import('../../components/Market/MPProductContainer.vue' /* webpackChunkName: "components/market-m-p-product-container" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductContainerSkeleton = () => import('../../components/Market/MPProductContainerSkeleton.vue' /* webpackChunkName: "components/market-m-p-product-container-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductLableOnCredit = () => import('../../components/Market/MPProductLableOnCredit.vue' /* webpackChunkName: "components/market-m-p-product-lable-on-credit" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductReview = () => import('../../components/Market/MPProductReview.vue' /* webpackChunkName: "components/market-m-p-product-review" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductReviewMessages = () => import('../../components/Market/MPProductReviewMessages.vue' /* webpackChunkName: "components/market-m-p-product-review-messages" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductReviewModal = () => import('../../components/Market/MPProductReviewModal.vue' /* webpackChunkName: "components/market-m-p-product-review-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductReviews = () => import('../../components/Market/MPProductReviews.vue' /* webpackChunkName: "components/market-m-p-product-reviews" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductZoomSlider = () => import('../../components/Market/MPProductZoomSlider.vue' /* webpackChunkName: "components/market-m-p-product-zoom-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductsEmpty = () => import('../../components/Market/MPProductsEmpty.vue' /* webpackChunkName: "components/market-m-p-products-empty" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductsEmptySearch = () => import('../../components/Market/MPProductsEmptySearch.vue' /* webpackChunkName: "components/market-m-p-products-empty-search" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductsFoundCount = () => import('../../components/Market/MPProductsFoundCount.vue' /* webpackChunkName: "components/market-m-p-products-found-count" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductsLoading = () => import('../../components/Market/MPProductsLoading.vue' /* webpackChunkName: "components/market-m-p-products-loading" */).then(c => wrapFunctional(c.default || c))
export const MarketMPProductsTagTitleDescription = () => import('../../components/Market/MPProductsTagTitleDescription.vue' /* webpackChunkName: "components/market-m-p-products-tag-title-description" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPromoBaner = () => import('../../components/Market/MPPromoBaner.vue' /* webpackChunkName: "components/market-m-p-promo-baner" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPromoBanerFoProduct = () => import('../../components/Market/MPPromoBanerFoProduct.vue' /* webpackChunkName: "components/market-m-p-promo-baner-fo-product" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPromoInfoBlock = () => import('../../components/Market/MPPromoInfoBlock.vue' /* webpackChunkName: "components/market-m-p-promo-info-block" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPromoLabelIninstallation = () => import('../../components/Market/MPPromoLabelIninstallation.vue' /* webpackChunkName: "components/market-m-p-promo-label-ininstallation" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPromoLabelOnProduct = () => import('../../components/Market/MPPromoLabelOnProduct.vue' /* webpackChunkName: "components/market-m-p-promo-label-on-product" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPromoTooltipFoCheckout = () => import('../../components/Market/MPPromoTooltipFoCheckout.vue' /* webpackChunkName: "components/market-m-p-promo-tooltip-fo-checkout" */).then(c => wrapFunctional(c.default || c))
export const MarketMPPudoMapSelect = () => import('../../components/Market/MPPudoMapSelect.vue' /* webpackChunkName: "components/market-m-p-pudo-map-select" */).then(c => wrapFunctional(c.default || c))
export const MarketMPRating = () => import('../../components/Market/MPRating.vue' /* webpackChunkName: "components/market-m-p-rating" */).then(c => wrapFunctional(c.default || c))
export const MarketMPRatingCount = () => import('../../components/Market/MPRatingCount.vue' /* webpackChunkName: "components/market-m-p-rating-count" */).then(c => wrapFunctional(c.default || c))
export const MarketMPRatingEmpty = () => import('../../components/Market/MPRatingEmpty.vue' /* webpackChunkName: "components/market-m-p-rating-empty" */).then(c => wrapFunctional(c.default || c))
export const MarketMPSaleDayBreadcrumbs = () => import('../../components/Market/MPSaleDayBreadcrumbs.vue' /* webpackChunkName: "components/market-m-p-sale-day-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const MarketMPSaleDayCounterOff = () => import('../../components/Market/MPSaleDayCounterOff.vue' /* webpackChunkName: "components/market-m-p-sale-day-counter-off" */).then(c => wrapFunctional(c.default || c))
export const MarketMPSeoCategoryTags = () => import('../../components/Market/MPSeoCategoryTags.vue' /* webpackChunkName: "components/market-m-p-seo-category-tags" */).then(c => wrapFunctional(c.default || c))
export const MarketMPTitleSEO = () => import('../../components/Market/MPTitleSEO.vue' /* webpackChunkName: "components/market-m-p-title-s-e-o" */).then(c => wrapFunctional(c.default || c))
export const MarketOrderSuccessRateDialog = () => import('../../components/Market/OrderSuccessRateDialog.vue' /* webpackChunkName: "components/market-order-success-rate-dialog" */).then(c => wrapFunctional(c.default || c))
export const MarketPartnerSuccessRate = () => import('../../components/Market/PartnerSuccessRate.vue' /* webpackChunkName: "components/market-partner-success-rate" */).then(c => wrapFunctional(c.default || c))
export const MarketPudoAzerpoctPointInfo = () => import('../../components/Market/PudoAzerpoctPointInfo.vue' /* webpackChunkName: "components/market-pudo-azerpoct-point-info" */).then(c => wrapFunctional(c.default || c))
export const MarketPudoPointInfo = () => import('../../components/Market/PudoPointInfo.vue' /* webpackChunkName: "components/market-pudo-point-info" */).then(c => wrapFunctional(c.default || c))
export const MarketReplaceToCart = () => import('../../components/Market/ReplaceToCart.vue' /* webpackChunkName: "components/market-replace-to-cart" */).then(c => wrapFunctional(c.default || c))
export const MarketRewriteAnswer = () => import('../../components/Market/RewriteAnswer.vue' /* webpackChunkName: "components/market-rewrite-answer" */).then(c => wrapFunctional(c.default || c))
export const MarketSuccessRateAddtoCartDialog = () => import('../../components/Market/SuccessRateAddtoCartDialog.vue' /* webpackChunkName: "components/market-success-rate-addto-cart-dialog" */).then(c => wrapFunctional(c.default || c))
export const MarketUserCreditLimit = () => import('../../components/Market/UserCreditLimit.vue' /* webpackChunkName: "components/market-user-credit-limit" */).then(c => wrapFunctional(c.default || c))
export const MarketVatBadge = () => import('../../components/Market/VatBadge.vue' /* webpackChunkName: "components/market-vat-badge" */).then(c => wrapFunctional(c.default || c))
export const MobileProductCategoriesMenuBlock = () => import('../../components/MobileProductCategoriesMenu/Block.vue' /* webpackChunkName: "components/mobile-product-categories-menu-block" */).then(c => wrapFunctional(c.default || c))
export const OrderAccountOrderContent = () => import('../../components/Order/AccountOrderContent.vue' /* webpackChunkName: "components/order-account-order-content" */).then(c => wrapFunctional(c.default || c))
export const OrderAccountOrderTitle = () => import('../../components/Order/AccountOrderTitle.vue' /* webpackChunkName: "components/order-account-order-title" */).then(c => wrapFunctional(c.default || c))
export const OrderCheckoutDeliveryTimeInfoPudo = () => import('../../components/Order/CheckoutDeliveryTimeInfoPudo.vue' /* webpackChunkName: "components/order-checkout-delivery-time-info-pudo" */).then(c => wrapFunctional(c.default || c))
export const OrderMinAndAvailableRepeatOrderPopup = () => import('../../components/Order/MinAndAvailableRepeatOrderPopup.vue' /* webpackChunkName: "components/order-min-and-available-repeat-order-popup" */).then(c => wrapFunctional(c.default || c))
export const OrderNotAllRepeatOrderPopup = () => import('../../components/Order/NotAllRepeatOrderPopup.vue' /* webpackChunkName: "components/order-not-all-repeat-order-popup" */).then(c => wrapFunctional(c.default || c))
export const OrderNothingRepeatOrderPopup = () => import('../../components/Order/NothingRepeatOrderPopup.vue' /* webpackChunkName: "components/order-nothing-repeat-order-popup" */).then(c => wrapFunctional(c.default || c))
export const OrderInformation = () => import('../../components/Order/OrderInformation.vue' /* webpackChunkName: "components/order-information" */).then(c => wrapFunctional(c.default || c))
export const OrderLineItem = () => import('../../components/Order/OrderLineItem.vue' /* webpackChunkName: "components/order-line-item" */).then(c => wrapFunctional(c.default || c))
export const OrderLineItems = () => import('../../components/Order/OrderLineItems.vue' /* webpackChunkName: "components/order-line-items" */).then(c => wrapFunctional(c.default || c))
export const OrderLoanDetailsBtn = () => import('../../components/Order/OrderLoanDetailsBtn.vue' /* webpackChunkName: "components/order-loan-details-btn" */).then(c => wrapFunctional(c.default || c))
export const OrderLoanStatus = () => import('../../components/Order/OrderLoanStatus.vue' /* webpackChunkName: "components/order-loan-status" */).then(c => wrapFunctional(c.default || c))
export const OrderPaymentStatus = () => import('../../components/Order/OrderPaymentStatus.vue' /* webpackChunkName: "components/order-payment-status" */).then(c => wrapFunctional(c.default || c))
export const OrderPaymentStatusTitle = () => import('../../components/Order/OrderPaymentStatusTitle.vue' /* webpackChunkName: "components/order-payment-status-title" */).then(c => wrapFunctional(c.default || c))
export const OrderPudoDeliveryWarning = () => import('../../components/Order/OrderPudoDeliveryWarning.vue' /* webpackChunkName: "components/order-pudo-delivery-warning" */).then(c => wrapFunctional(c.default || c))
export const OrderRepeat = () => import('../../components/Order/OrderRepeat.vue' /* webpackChunkName: "components/order-repeat" */).then(c => wrapFunctional(c.default || c))
export const OrderStatusTimeline = () => import('../../components/Order/OrderStatusTimeline.vue' /* webpackChunkName: "components/order-status-timeline" */).then(c => wrapFunctional(c.default || c))
export const OrderOrdersBoxes = () => import('../../components/Order/OrdersBoxes.vue' /* webpackChunkName: "components/order-orders-boxes" */).then(c => wrapFunctional(c.default || c))
export const OrderOrdersBoxesInfoBlock = () => import('../../components/Order/OrdersBoxesInfoBlock.vue' /* webpackChunkName: "components/order-orders-boxes-info-block" */).then(c => wrapFunctional(c.default || c))
export const OrderPayCashForLoanAlert = () => import('../../components/Order/PayCashForLoanAlert.vue' /* webpackChunkName: "components/order-pay-cash-for-loan-alert" */).then(c => wrapFunctional(c.default || c))
export const PagesBecomePartnerForm = () => import('../../components/Pages/BecomePartnerForm.vue' /* webpackChunkName: "components/pages-become-partner-form" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqs = () => import('../../components/Pages/Faqs.vue' /* webpackChunkName: "components/pages-faqs" */).then(c => wrapFunctional(c.default || c))
export const PagesNav = () => import('../../components/Pages/Nav.vue' /* webpackChunkName: "components/pages-nav" */).then(c => wrapFunctional(c.default || c))
export const PagesBecomePartnerAz = () => import('../../components/Pages/become-partner-az.vue' /* webpackChunkName: "components/pages-become-partner-az" */).then(c => wrapFunctional(c.default || c))
export const PagesBecomePartnerRu = () => import('../../components/Pages/become-partner-ru.vue' /* webpackChunkName: "components/pages-become-partner-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesBenefitsUmicoPremiumAz = () => import('../../components/Pages/benefits-umico-premium-az.vue' /* webpackChunkName: "components/pages-benefits-umico-premium-az" */).then(c => wrapFunctional(c.default || c))
export const PagesBenefitsUmicoPremiumRu = () => import('../../components/Pages/benefits-umico-premium-ru.vue' /* webpackChunkName: "components/pages-benefits-umico-premium-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesContactAz = () => import('../../components/Pages/contact-az.vue' /* webpackChunkName: "components/pages-contact-az" */).then(c => wrapFunctional(c.default || c))
export const PagesContactRu = () => import('../../components/Pages/contact-ru.vue' /* webpackChunkName: "components/pages-contact-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesDeliveryAzerpoctAz = () => import('../../components/Pages/delivery-azerpoct-az.vue' /* webpackChunkName: "components/pages-delivery-azerpoct-az" */).then(c => wrapFunctional(c.default || c))
export const PagesDeliveryAzerpoctRu = () => import('../../components/Pages/delivery-azerpoct-ru.vue' /* webpackChunkName: "components/pages-delivery-azerpoct-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqAz = () => import('../../components/Pages/faq-az.vue' /* webpackChunkName: "components/pages-faq-az" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqEn = () => import('../../components/Pages/faq-en.vue' /* webpackChunkName: "components/pages-faq-en" */).then(c => wrapFunctional(c.default || c))
export const PagesFaqRu = () => import('../../components/Pages/faq-ru.vue' /* webpackChunkName: "components/pages-faq-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToBuyAz = () => import('../../components/Pages/how-to-buy-az.vue' /* webpackChunkName: "components/pages-how-to-buy-az" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToBuyRu = () => import('../../components/Pages/how-to-buy-ru.vue' /* webpackChunkName: "components/pages-how-to-buy-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToPlaceOrderAz = () => import('../../components/Pages/how-to-place-order-az.vue' /* webpackChunkName: "components/pages-how-to-place-order-az" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToPlaceOrderRu = () => import('../../components/Pages/how-to-place-order-ru.vue' /* webpackChunkName: "components/pages-how-to-place-order-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesMobileAppsAz = () => import('../../components/Pages/mobile-apps-az.vue' /* webpackChunkName: "components/pages-mobile-apps-az" */).then(c => wrapFunctional(c.default || c))
export const PagesMobileAppsRu = () => import('../../components/Pages/mobile-apps-ru.vue' /* webpackChunkName: "components/pages-mobile-apps-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsAll = () => import('../../components/Pages/pick-up-points-all.vue' /* webpackChunkName: "components/pages-pick-up-points-all" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsAz = () => import('../../components/Pages/pick-up-points-az.vue' /* webpackChunkName: "components/pages-pick-up-points-az" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsRu = () => import('../../components/Pages/pick-up-points-ru.vue' /* webpackChunkName: "components/pages-pick-up-points-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesPolicyAz = () => import('../../components/Pages/policy-az.vue' /* webpackChunkName: "components/pages-policy-az" */).then(c => wrapFunctional(c.default || c))
export const PagesPolicyRu = () => import('../../components/Pages/policy-ru.vue' /* webpackChunkName: "components/pages-policy-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesPrivacyPolicyAz = () => import('../../components/Pages/privacy-policy-az.vue' /* webpackChunkName: "components/pages-privacy-policy-az" */).then(c => wrapFunctional(c.default || c))
export const PagesPrivacyPolicyRu = () => import('../../components/Pages/privacy-policy-ru.vue' /* webpackChunkName: "components/pages-privacy-policy-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesPromocodesAz = () => import('../../components/Pages/promocodes-az.vue' /* webpackChunkName: "components/pages-promocodes-az" */).then(c => wrapFunctional(c.default || c))
export const PagesPromocodesRu = () => import('../../components/Pages/promocodes-ru.vue' /* webpackChunkName: "components/pages-promocodes-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesPublicOfferAz = () => import('../../components/Pages/public-offer-az.vue' /* webpackChunkName: "components/pages-public-offer-az" */).then(c => wrapFunctional(c.default || c))
export const PagesPublicOfferRu = () => import('../../components/Pages/public-offer-ru.vue' /* webpackChunkName: "components/pages-public-offer-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesQuestionsAnswersAz = () => import('../../components/Pages/questions-answers-az.vue' /* webpackChunkName: "components/pages-questions-answers-az" */).then(c => wrapFunctional(c.default || c))
export const PagesQuestionsAnswersRu = () => import('../../components/Pages/questions-answers-ru.vue' /* webpackChunkName: "components/pages-questions-answers-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesRefundAzerpoctAz = () => import('../../components/Pages/refund-azerpoct-az.vue' /* webpackChunkName: "components/pages-refund-azerpoct-az" */).then(c => wrapFunctional(c.default || c))
export const PagesRefundAzerpoctRu = () => import('../../components/Pages/refund-azerpoct-ru.vue' /* webpackChunkName: "components/pages-refund-azerpoct-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesRulesAz = () => import('../../components/Pages/rules-az.vue' /* webpackChunkName: "components/pages-rules-az" */).then(c => wrapFunctional(c.default || c))
export const PagesRulesEn = () => import('../../components/Pages/rules-en.vue' /* webpackChunkName: "components/pages-rules-en" */).then(c => wrapFunctional(c.default || c))
export const PagesRulesRu = () => import('../../components/Pages/rules-ru.vue' /* webpackChunkName: "components/pages-rules-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesServiceCentresAz = () => import('../../components/Pages/service-centres-az.vue' /* webpackChunkName: "components/pages-service-centres-az" */).then(c => wrapFunctional(c.default || c))
export const PagesServiceCentresRu = () => import('../../components/Pages/service-centres-ru.vue' /* webpackChunkName: "components/pages-service-centres-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesShippingPaymentAz = () => import('../../components/Pages/shipping-payment-az.vue' /* webpackChunkName: "components/pages-shipping-payment-az" */).then(c => wrapFunctional(c.default || c))
export const PagesShippingPaymentRu = () => import('../../components/Pages/shipping-payment-ru.vue' /* webpackChunkName: "components/pages-shipping-payment-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesShoppingPublicOfferAz = () => import('../../components/Pages/shopping-public-offer-az.vue' /* webpackChunkName: "components/pages-shopping-public-offer-az" */).then(c => wrapFunctional(c.default || c))
export const PagesShoppingPublicOfferRu = () => import('../../components/Pages/shopping-public-offer-ru.vue' /* webpackChunkName: "components/pages-shopping-public-offer-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesStartSellingOnUmicoMarketAz = () => import('../../components/Pages/start-selling-on-umico-market-az.vue' /* webpackChunkName: "components/pages-start-selling-on-umico-market-az" */).then(c => wrapFunctional(c.default || c))
export const PagesStartSellingOnUmicoMarketRu = () => import('../../components/Pages/start-selling-on-umico-market-ru.vue' /* webpackChunkName: "components/pages-start-selling-on-umico-market-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesSubscribeUmicoPremiumAz = () => import('../../components/Pages/subscribe-umico-premium-az.vue' /* webpackChunkName: "components/pages-subscribe-umico-premium-az" */).then(c => wrapFunctional(c.default || c))
export const PagesSubscribeUmicoPremiumRu = () => import('../../components/Pages/subscribe-umico-premium-ru.vue' /* webpackChunkName: "components/pages-subscribe-umico-premium-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesUmicoDeliveryServiceAz = () => import('../../components/Pages/umico-delivery-service-az.vue' /* webpackChunkName: "components/pages-umico-delivery-service-az" */).then(c => wrapFunctional(c.default || c))
export const PagesUmicoDeliveryServiceRu = () => import('../../components/Pages/umico-delivery-service-ru.vue' /* webpackChunkName: "components/pages-umico-delivery-service-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesWhereToGetAz = () => import('../../components/Pages/where-to-get-az.vue' /* webpackChunkName: "components/pages-where-to-get-az" */).then(c => wrapFunctional(c.default || c))
export const PagesWhereToGetRu = () => import('../../components/Pages/where-to-get-ru.vue' /* webpackChunkName: "components/pages-where-to-get-ru" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCatalogPlaceholder = () => import('../../components/Placeholders/CatalogPlaceholder.vue' /* webpackChunkName: "components/placeholders-catalog-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCheckoutMethodsPlaceholder = () => import('../../components/Placeholders/CheckoutMethodsPlaceholder.vue' /* webpackChunkName: "components/placeholders-checkout-methods-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCompareProductsPlaceholder = () => import('../../components/Placeholders/CompareProductsPlaceholder.vue' /* webpackChunkName: "components/placeholders-compare-products-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersMyOrdersPlaceholder = () => import('../../components/Placeholders/MyOrdersPlaceholder.vue' /* webpackChunkName: "components/placeholders-my-orders-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersPaymentStatusPlaceholder = () => import('../../components/Placeholders/PaymentStatusPlaceholder.vue' /* webpackChunkName: "components/placeholders-payment-status-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersSingleOrderPagePlaceholder = () => import('../../components/Placeholders/SingleOrderPagePlaceholder.vue' /* webpackChunkName: "components/placeholders-single-order-page-placeholder" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoriesMenuBlock = () => import('../../components/ProductCategoriesMenu/Block.vue' /* webpackChunkName: "components/product-categories-menu-block" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoriesMenuItem = () => import('../../components/ProductCategoriesMenu/ProductCategoriesMenuItem.vue' /* webpackChunkName: "components/product-categories-menu-item" */).then(c => wrapFunctional(c.default || c))
export const ProductMegaDiscountsMenuItem = () => import('../../components/ProductCategoriesMenu/ProductMegaDiscountsMenuItem.vue' /* webpackChunkName: "components/product-mega-discounts-menu-item" */).then(c => wrapFunctional(c.default || c))
export const ProductCategoriesMenuSmartProductCategoriesMenu = () => import('../../components/ProductCategoriesMenu/SmartProductCategoriesMenu.vue' /* webpackChunkName: "components/product-categories-menu-smart-product-categories-menu" */).then(c => wrapFunctional(c.default || c))
export const PromotionMPPromoCategoryProd = () => import('../../components/Promotion/MPPromoCategoryProd.vue' /* webpackChunkName: "components/promotion-m-p-promo-category-prod" */).then(c => wrapFunctional(c.default || c))
export const PromotionMPPromoTopProd = () => import('../../components/Promotion/MPPromoTopProd.vue' /* webpackChunkName: "components/promotion-m-p-promo-top-prod" */).then(c => wrapFunctional(c.default || c))
export const PromotionMPPromotionBanner = () => import('../../components/Promotion/MPPromotionBanner.vue' /* webpackChunkName: "components/promotion-m-p-promotion-banner" */).then(c => wrapFunctional(c.default || c))
export const PromotionMPPromotionCategory = () => import('../../components/Promotion/MPPromotionCategory.vue' /* webpackChunkName: "components/promotion-m-p-promotion-category" */).then(c => wrapFunctional(c.default || c))
export const SearchMPSearchPageTitle = () => import('../../components/Search/MPSearchPageTitle.vue' /* webpackChunkName: "components/search-m-p-search-page-title" */).then(c => wrapFunctional(c.default || c))
export const SearchInstead = () => import('../../components/Search/SearchInstead.vue' /* webpackChunkName: "components/search-instead" */).then(c => wrapFunctional(c.default || c))
export const SellersMPSellersTitle = () => import('../../components/Sellers/MPSellersTitle.vue' /* webpackChunkName: "components/sellers-m-p-sellers-title" */).then(c => wrapFunctional(c.default || c))
export const TagsMPTagsCategoriesPopup = () => import('../../components/Tags/MPTagsCategoriesPopup.vue' /* webpackChunkName: "components/tags-m-p-tags-categories-popup" */).then(c => wrapFunctional(c.default || c))
export const TagsMPTagsTitle = () => import('../../components/Tags/MPTagsTitle.vue' /* webpackChunkName: "components/tags-m-p-tags-title" */).then(c => wrapFunctional(c.default || c))
export const UIAlert = () => import('../../components/UI/Alert.vue' /* webpackChunkName: "components/u-i-alert" */).then(c => wrapFunctional(c.default || c))
export const UIAzerpoctPudoCitiesSelect = () => import('../../components/UI/AzerpoctPudoCitiesSelect.vue' /* webpackChunkName: "components/u-i-azerpoct-pudo-cities-select" */).then(c => wrapFunctional(c.default || c))
export const UIBoxForm = () => import('../../components/UI/BoxForm.vue' /* webpackChunkName: "components/u-i-box-form" */).then(c => wrapFunctional(c.default || c))
export const UIBoxTitle = () => import('../../components/UI/BoxTitle.vue' /* webpackChunkName: "components/u-i-box-title" */).then(c => wrapFunctional(c.default || c))
export const UIButton = () => import('../../components/UI/Button.vue' /* webpackChunkName: "components/u-i-button" */).then(c => wrapFunctional(c.default || c))
export const UIButtonSwipe = () => import('../../components/UI/ButtonSwipe.vue' /* webpackChunkName: "components/u-i-button-swipe" */).then(c => wrapFunctional(c.default || c))
export const UIContentLoading = () => import('../../components/UI/ContentLoading.vue' /* webpackChunkName: "components/u-i-content-loading" */).then(c => wrapFunctional(c.default || c))
export const UICountDownTimer = () => import('../../components/UI/CountDownTimer.vue' /* webpackChunkName: "components/u-i-count-down-timer" */).then(c => wrapFunctional(c.default || c))
export const UICountDownTimerCounter = () => import('../../components/UI/CountDownTimerCounter.vue' /* webpackChunkName: "components/u-i-count-down-timer-counter" */).then(c => wrapFunctional(c.default || c))
export const UICountDownTimerProduct = () => import('../../components/UI/CountDownTimerProduct.vue' /* webpackChunkName: "components/u-i-count-down-timer-product" */).then(c => wrapFunctional(c.default || c))
export const UICustomSelect = () => import('../../components/UI/CustomSelect.vue' /* webpackChunkName: "components/u-i-custom-select" */).then(c => wrapFunctional(c.default || c))
export const UIDebugBtn = () => import('../../components/UI/DebugBtn.vue' /* webpackChunkName: "components/u-i-debug-btn" */).then(c => wrapFunctional(c.default || c))
export const UIDeliveryTwoHoursBadge = () => import('../../components/UI/DeliveryTwoHoursBadge.vue' /* webpackChunkName: "components/u-i-delivery-two-hours-badge" */).then(c => wrapFunctional(c.default || c))
export const UIFavoriteProductsSkeleton = () => import('../../components/UI/FavoriteProductsSkeleton.vue' /* webpackChunkName: "components/u-i-favorite-products-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UIFormAlert = () => import('../../components/UI/FormAlert.vue' /* webpackChunkName: "components/u-i-form-alert" */).then(c => wrapFunctional(c.default || c))
export const UIFormButton = () => import('../../components/UI/FormButton.vue' /* webpackChunkName: "components/u-i-form-button" */).then(c => wrapFunctional(c.default || c))
export const UIFormDatePickerInput = () => import('../../components/UI/FormDatePickerInput.vue' /* webpackChunkName: "components/u-i-form-date-picker-input" */).then(c => wrapFunctional(c.default || c))
export const UIFormGenderInput = () => import('../../components/UI/FormGenderInput.vue' /* webpackChunkName: "components/u-i-form-gender-input" */).then(c => wrapFunctional(c.default || c))
export const UIFormInput = () => import('../../components/UI/FormInput.vue' /* webpackChunkName: "components/u-i-form-input" */).then(c => wrapFunctional(c.default || c))
export const UIFormPhoneNumberInput = () => import('../../components/UI/FormPhoneNumberInput.vue' /* webpackChunkName: "components/u-i-form-phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const UIFormRadioButton = () => import('../../components/UI/FormRadioButton.vue' /* webpackChunkName: "components/u-i-form-radio-button" */).then(c => wrapFunctional(c.default || c))
export const UIFormSelect = () => import('../../components/UI/FormSelect.vue' /* webpackChunkName: "components/u-i-form-select" */).then(c => wrapFunctional(c.default || c))
export const UIKeenSlider = () => import('../../components/UI/KeenSlider.vue' /* webpackChunkName: "components/u-i-keen-slider" */).then(c => wrapFunctional(c.default || c))
export const UIKeywords = () => import('../../components/UI/Keywords.vue' /* webpackChunkName: "components/u-i-keywords" */).then(c => wrapFunctional(c.default || c))
export const UIMainPageProductsSkeleton = () => import('../../components/UI/MainPageProductsSkeleton.vue' /* webpackChunkName: "components/u-i-main-page-products-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UIModalCheckbox = () => import('../../components/UI/ModalCheckbox.vue' /* webpackChunkName: "components/u-i-modal-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UISeoTag = () => import('../../components/UI/SeoTag.vue' /* webpackChunkName: "components/u-i-seo-tag" */).then(c => wrapFunctional(c.default || c))
export const UISlideUpDown = () => import('../../components/UI/SlideUpDown.vue' /* webpackChunkName: "components/u-i-slide-up-down" */).then(c => wrapFunctional(c.default || c))
export const UISmallPopup = () => import('../../components/UI/SmallPopup.vue' /* webpackChunkName: "components/u-i-small-popup" */).then(c => wrapFunctional(c.default || c))
export const UISuccessRateBadge = () => import('../../components/UI/SuccessRateBadge.vue' /* webpackChunkName: "components/u-i-success-rate-badge" */).then(c => wrapFunctional(c.default || c))
export const UITooltip = () => import('../../components/UI/Tooltip.vue' /* webpackChunkName: "components/u-i-tooltip" */).then(c => wrapFunctional(c.default || c))
export const UITurningArrowIcon = () => import('../../components/UI/TurningArrowIcon.vue' /* webpackChunkName: "components/u-i-turning-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const UIUAlert = () => import('../../components/UI/UAlert.vue' /* webpackChunkName: "components/u-i-u-alert" */).then(c => wrapFunctional(c.default || c))
export const UIUBox = () => import('../../components/UI/UBox.vue' /* webpackChunkName: "components/u-i-u-box" */).then(c => wrapFunctional(c.default || c))
export const UIUButton = () => import('../../components/UI/UButton.vue' /* webpackChunkName: "components/u-i-u-button" */).then(c => wrapFunctional(c.default || c))
export const UIUCard = () => import('../../components/UI/UCard.vue' /* webpackChunkName: "components/u-i-u-card" */).then(c => wrapFunctional(c.default || c))
export const UIUChart = () => import('../../components/UI/UChart.vue' /* webpackChunkName: "components/u-i-u-chart" */).then(c => wrapFunctional(c.default || c))
export const UIUCheckbox = () => import('../../components/UI/UCheckbox.vue' /* webpackChunkName: "components/u-i-u-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UIUContentLoader = () => import('../../components/UI/UContentLoader.vue' /* webpackChunkName: "components/u-i-u-content-loader" */).then(c => wrapFunctional(c.default || c))
export const UIUPages = () => import('../../components/UI/UPages.vue' /* webpackChunkName: "components/u-i-u-pages" */).then(c => wrapFunctional(c.default || c))
export const UIUProfilePictureUploader = () => import('../../components/UI/UProfilePictureUploader.vue' /* webpackChunkName: "components/u-i-u-profile-picture-uploader" */).then(c => wrapFunctional(c.default || c))
export const UIUSelect = () => import('../../components/UI/USelect.vue' /* webpackChunkName: "components/u-i-u-select" */).then(c => wrapFunctional(c.default || c))
export const UIUSlider = () => import('../../components/UI/USlider.vue' /* webpackChunkName: "components/u-i-u-slider" */).then(c => wrapFunctional(c.default || c))
export const UIUSpace = () => import('../../components/UI/USpace.vue' /* webpackChunkName: "components/u-i-u-space" */).then(c => wrapFunctional(c.default || c))
export const UserAgreements = () => import('../../components/UserAgreements/UserAgreements.vue' /* webpackChunkName: "components/user-agreements" */).then(c => wrapFunctional(c.default || c))
export const UAddressInput = () => import('../../components/u/AddressInput.vue' /* webpackChunkName: "components/u-address-input" */).then(c => wrapFunctional(c.default || c))
export const UAlertInfo = () => import('../../components/u/AlertInfo.vue' /* webpackChunkName: "components/u-alert-info" */).then(c => wrapFunctional(c.default || c))
export const UInput = () => import('../../components/u/Input.vue' /* webpackChunkName: "components/u-input" */).then(c => wrapFunctional(c.default || c))
export const UUCheckboxMark = () => import('../../components/u/UCheckboxMark.vue' /* webpackChunkName: "components/u-u-checkbox-mark" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusBaloon = () => import('../../components/Account/BonusCard/BonusBaloon.vue' /* webpackChunkName: "components/account-bonus-baloon" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusCard = () => import('../../components/Account/BonusCard/Card.vue' /* webpackChunkName: "components/account-bonus-card" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusCardChart = () => import('../../components/Account/BonusCard/Chart.vue' /* webpackChunkName: "components/account-bonus-card-chart" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusCardDiary = () => import('../../components/Account/BonusCard/Diary.vue' /* webpackChunkName: "components/account-bonus-card-diary" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusCardDiaryElement = () => import('../../components/Account/BonusCard/DiaryElement.vue' /* webpackChunkName: "components/account-bonus-card-diary-element" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusCardFilters = () => import('../../components/Account/BonusCard/Filters.vue' /* webpackChunkName: "components/account-bonus-card-filters" */).then(c => wrapFunctional(c.default || c))
export const AccountBonusCardLastOperations = () => import('../../components/Account/BonusCard/LastOperations.vue' /* webpackChunkName: "components/account-bonus-card-last-operations" */).then(c => wrapFunctional(c.default || c))
export const AccountCreditLine = () => import('../../components/Account/CreditLine/CreditLine.vue' /* webpackChunkName: "components/account-credit-line" */).then(c => wrapFunctional(c.default || c))
export const AccountFavoritesAlternativeProducts = () => import('../../components/Account/Favorites/AlternativeProducts.vue' /* webpackChunkName: "components/account-favorites-alternative-products" */).then(c => wrapFunctional(c.default || c))
export const AccountOrder = () => import('../../components/Account/Orders/AccountOrder.vue' /* webpackChunkName: "components/account-order" */).then(c => wrapFunctional(c.default || c))
export const AccountOrders = () => import('../../components/Account/Orders/AccountOrders.vue' /* webpackChunkName: "components/account-orders" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersCancelOrderModal = () => import('../../components/Account/Orders/CancelOrderModal.vue' /* webpackChunkName: "components/account-orders-cancel-order-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderStatusLabel = () => import('../../components/Account/Orders/OrderStatusLabel.vue' /* webpackChunkName: "components/account-orders-order-status-label" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersReplayPaymentModal = () => import('../../components/Account/Orders/ReplayPaymentModal.vue' /* webpackChunkName: "components/account-orders-replay-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumBenefitsBlock = () => import('../../components/Account/Premium/BenefitsBlock.vue' /* webpackChunkName: "components/account-premium-benefits-block" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumContent = () => import('../../components/Account/Premium/Content.vue' /* webpackChunkName: "components/account-premium-content" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumFooterBlock = () => import('../../components/Account/Premium/FooterBlock.vue' /* webpackChunkName: "components/account-premium-footer-block" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumMainBlock = () => import('../../components/Account/Premium/MainBlock.vue' /* webpackChunkName: "components/account-premium-main-block" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumPros = () => import('../../components/Account/Premium/Pros.vue' /* webpackChunkName: "components/account-premium-pros" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumQrCodeModal = () => import('../../components/Account/Premium/QrCodeModal.vue' /* webpackChunkName: "components/account-premium-qr-code-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountPremiumSubscribeButton = () => import('../../components/Account/Premium/SubscribeButton.vue' /* webpackChunkName: "components/account-premium-subscribe-button" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileCheckoutProfileAddresses = () => import('../../components/Account/Profile/CheckoutProfileAddresses.vue' /* webpackChunkName: "components/account-profile-checkout-profile-addresses" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileCheckoutProfileAddressesAdd = () => import('../../components/Account/Profile/CheckoutProfileAddressesAdd.vue' /* webpackChunkName: "components/account-profile-checkout-profile-addresses-add" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileCheckoutProfileAddressesEdit = () => import('../../components/Account/Profile/CheckoutProfileAddressesEdit.vue' /* webpackChunkName: "components/account-profile-checkout-profile-addresses-edit" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileFormInputPassport = () => import('../../components/Account/Profile/ProfileFormInputPassport.vue' /* webpackChunkName: "components/account-profile-form-input-passport" */).then(c => wrapFunctional(c.default || c))
export const AccountProfileHeader = () => import('../../components/Account/Profile/ProfileHeader.vue' /* webpackChunkName: "components/account-profile-header" */).then(c => wrapFunctional(c.default || c))
export const AccountProfilePicture = () => import('../../components/Account/Profile/ProfilePicture.vue' /* webpackChunkName: "components/account-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsEmptyLeaveReviewTab = () => import('../../components/Account/Reviews/EmptyLeaveReviewTab.vue' /* webpackChunkName: "components/account-reviews-empty-leave-review-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsEmptyUserReviewsTab = () => import('../../components/Account/Reviews/EmptyUserReviewsTab.vue' /* webpackChunkName: "components/account-reviews-empty-user-reviews-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsLeaveReviewTab = () => import('../../components/Account/Reviews/LeaveReviewTab.vue' /* webpackChunkName: "components/account-reviews-leave-review-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsRejectedReviewTooltip = () => import('../../components/Account/Reviews/RejectedReviewTooltip.vue' /* webpackChunkName: "components/account-reviews-rejected-review-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsTabs = () => import('../../components/Account/Reviews/Tabs.vue' /* webpackChunkName: "components/account-reviews-tabs" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsUserReviewAnswer = () => import('../../components/Account/Reviews/UserReviewAnswer.vue' /* webpackChunkName: "components/account-reviews-user-review-answer" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsUserReviewBox = () => import('../../components/Account/Reviews/UserReviewBox.vue' /* webpackChunkName: "components/account-reviews-user-review-box" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsUserReviewsTab = () => import('../../components/Account/Reviews/UserReviewsTab.vue' /* webpackChunkName: "components/account-reviews-user-reviews-tab" */).then(c => wrapFunctional(c.default || c))
export const AccountSidebar = () => import('../../components/Account/Sidebar/index.vue' /* webpackChunkName: "components/account-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AccountTTable = () => import('../../components/Account/TTable/TTable.vue' /* webpackChunkName: "components/account-t-table" */).then(c => wrapFunctional(c.default || c))
export const AccountTTableBody = () => import('../../components/Account/TTable/TTableBody.vue' /* webpackChunkName: "components/account-t-table-body" */).then(c => wrapFunctional(c.default || c))
export const AccountTTableBonus = () => import('../../components/Account/TTable/TTableBonus.vue' /* webpackChunkName: "components/account-t-table-bonus" */).then(c => wrapFunctional(c.default || c))
export const AccountTTableHeader = () => import('../../components/Account/TTable/TTableHeader.vue' /* webpackChunkName: "components/account-t-table-header" */).then(c => wrapFunctional(c.default || c))
export const AccountTTableOperationProcessing = () => import('../../components/Account/TTable/TTableOperationProcessing.vue' /* webpackChunkName: "components/account-t-table-operation-processing" */).then(c => wrapFunctional(c.default || c))
export const AccountTTablePartner = () => import('../../components/Account/TTable/TTablePartner.vue' /* webpackChunkName: "components/account-t-table-partner" */).then(c => wrapFunctional(c.default || c))
export const AccountTTableRow = () => import('../../components/Account/TTable/TTableRow.vue' /* webpackChunkName: "components/account-t-table-row" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsDateRange = () => import('../../components/Account/Transactions/DateRange.vue' /* webpackChunkName: "components/account-transactions-date-range" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsFilters = () => import('../../components/Account/Transactions/Filters.vue' /* webpackChunkName: "components/account-transactions-filters" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsOperationTypeSelect = () => import('../../components/Account/Transactions/OperationTypeSelect.vue' /* webpackChunkName: "components/account-transactions-operation-type-select" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsPagination = () => import('../../components/Account/Transactions/Pagination.vue' /* webpackChunkName: "components/account-transactions-pagination" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsPartnerSelect = () => import('../../components/Account/Transactions/PartnerSelect.vue' /* webpackChunkName: "components/account-transactions-partner-select" */).then(c => wrapFunctional(c.default || c))
export const AccountTransactionsHistory = () => import('../../components/Account/Transactions/TransactionsHistory.vue' /* webpackChunkName: "components/account-transactions-history" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardLogo = () => import('../../components/Catalog/Cardfull/CardLogo.vue' /* webpackChunkName: "components/catalog-cardfull-card-logo" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardLogoCompanies = () => import('../../components/Catalog/Cardfull/CardLogoCompanies.vue' /* webpackChunkName: "components/catalog-cardfull-card-logo-companies" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardPartner = () => import('../../components/Catalog/Cardfull/CardPartner.vue' /* webpackChunkName: "components/catalog-cardfull-card-partner" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardPartnerCompanies = () => import('../../components/Catalog/Cardfull/CardPartnerCompanies.vue' /* webpackChunkName: "components/catalog-cardfull-card-partner-companies" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardPhoto = () => import('../../components/Catalog/Cardfull/CardPhoto.vue' /* webpackChunkName: "components/catalog-cardfull-card-photo" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardPos = () => import('../../components/Catalog/Cardfull/CardPos.vue' /* webpackChunkName: "components/catalog-cardfull-card-pos" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullCardPosSimple = () => import('../../components/Catalog/Cardfull/CardPosSimple.vue' /* webpackChunkName: "components/catalog-cardfull-card-pos-simple" */).then(c => wrapFunctional(c.default || c))
export const CatalogCardfullPosLocation = () => import('../../components/Catalog/Cardfull/PosLocation.vue' /* webpackChunkName: "components/catalog-cardfull-pos-location" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesSidebar = () => import('../../components/Catalog/Categories/CategoriesSidebar.vue' /* webpackChunkName: "components/catalog-categories-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesSidebarHeader = () => import('../../components/Catalog/Categories/CategoriesSidebarHeader.vue' /* webpackChunkName: "components/catalog-categories-sidebar-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesContentCatalog = () => import('../../components/Catalog/Categories/ContentCatalog.vue' /* webpackChunkName: "components/catalog-categories-content-catalog" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesDisplayButtons = () => import('../../components/Catalog/Categories/DisplayButtons.vue' /* webpackChunkName: "components/catalog-categories-display-buttons" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesPanelContent = () => import('../../components/Catalog/Categories/PanelContent.vue' /* webpackChunkName: "components/catalog-categories-panel-content" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesSortByCatalog = () => import('../../components/Catalog/Categories/SortByCatalog.vue' /* webpackChunkName: "components/catalog-categories-sort-by-catalog" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonBreadcrumbs = () => import('../../components/Catalog/Common/Breadcrumbs.vue' /* webpackChunkName: "components/catalog-common-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonCategoriesButtons = () => import('../../components/Catalog/Common/CategoriesButtons.vue' /* webpackChunkName: "components/catalog-common-categories-buttons" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonPartnerNotes = () => import('../../components/Catalog/Common/PartnerNotes.vue' /* webpackChunkName: "components/catalog-common-partner-notes" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonPosBenefits = () => import('../../components/Catalog/Common/PosBenefits.vue' /* webpackChunkName: "components/catalog-common-pos-benefits" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonPosOffers = () => import('../../components/Catalog/Common/PosOffers.vue' /* webpackChunkName: "components/catalog-common-pos-offers" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonPosPhotos = () => import('../../components/Catalog/Common/PosPhotos.vue' /* webpackChunkName: "components/catalog-common-pos-photos" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonPosTitle = () => import('../../components/Catalog/Common/PosTitle.vue' /* webpackChunkName: "components/catalog-common-pos-title" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonToggler = () => import('../../components/Catalog/Common/Toggler.vue' /* webpackChunkName: "components/catalog-common-toggler" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonUCashback = () => import('../../components/Catalog/Common/UCashback.vue' /* webpackChunkName: "components/catalog-common-u-cashback" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonUCashbackDiff = () => import('../../components/Catalog/Common/UCashbackDiff.vue' /* webpackChunkName: "components/catalog-common-u-cashback-diff" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonUCashbacktitle = () => import('../../components/Catalog/Common/UCashbacktitle.vue' /* webpackChunkName: "components/catalog-common-u-cashbacktitle" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonUFavorite = () => import('../../components/Catalog/Common/UFavorite.vue' /* webpackChunkName: "components/catalog-common-u-favorite" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonULogo = () => import('../../components/Catalog/Common/ULogo.vue' /* webpackChunkName: "components/catalog-common-u-logo" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonUUmiconotpay = () => import('../../components/Catalog/Common/UUmiconotpay.vue' /* webpackChunkName: "components/catalog-common-u-umiconotpay" */).then(c => wrapFunctional(c.default || c))
export const CatalogCommonUUmicopay = () => import('../../components/Catalog/Common/UUmicopay.vue' /* webpackChunkName: "components/catalog-common-u-umicopay" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartner = () => import('../../components/Catalog/Partner/Partner.vue' /* webpackChunkName: "components/catalog-partner" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartnerContacts = () => import('../../components/Catalog/Partner/PartnerContacts.vue' /* webpackChunkName: "components/catalog-partner-contacts" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartnerNotes = () => import('../../components/Catalog/Partner/PartnerNotes.vue' /* webpackChunkName: "components/catalog-partner-notes" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartnerPremiumPartner = () => import('../../components/Catalog/Partner/PremiumPartner.vue' /* webpackChunkName: "components/catalog-partner-premium-partner" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartnerPremiumPartnerAdvantages = () => import('../../components/Catalog/Partner/PremiumPartnerAdvantages.vue' /* webpackChunkName: "components/catalog-partner-premium-partner-advantages" */).then(c => wrapFunctional(c.default || c))
export const CatalogPartnerProductsWithCashback = () => import('../../components/Catalog/Partner/ProductsWithCashback.vue' /* webpackChunkName: "components/catalog-partner-products-with-cashback" */).then(c => wrapFunctional(c.default || c))
export const CatalogPos = () => import('../../components/Catalog/Pos/Pos.vue' /* webpackChunkName: "components/catalog-pos" */).then(c => wrapFunctional(c.default || c))
export const CatalogPosContacts = () => import('../../components/Catalog/Pos/PosContacts.vue' /* webpackChunkName: "components/catalog-pos-contacts" */).then(c => wrapFunctional(c.default || c))
export const CatalogPosOffers = () => import('../../components/Catalog/Pos/PosOffers.vue' /* webpackChunkName: "components/catalog-pos-offers" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckoutAnonimAnonymousRegisterPopup = () => import('../../components/Checkout/CheckoutAnonim/AnonymousRegisterPopup.vue' /* webpackChunkName: "components/checkout-checkout-anonim-anonymous-register-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckoutAnonimLoginUserAnonymous = () => import('../../components/Checkout/CheckoutAnonim/LoginUserAnonymous.vue' /* webpackChunkName: "components/checkout-checkout-anonim-login-user-anonymous" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckoutAnonimNecessaryLogin = () => import('../../components/Checkout/CheckoutAnonim/NecessaryLogin.vue' /* webpackChunkName: "components/checkout-checkout-anonim-necessary-login" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckoutAnonimSimpleLogin = () => import('../../components/Checkout/CheckoutAnonim/SimpleLogin.vue' /* webpackChunkName: "components/checkout-checkout-anonim-simple-login" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckoutSuccessPopupSpecialCashbackOffer = () => import('../../components/Checkout/CheckoutSuccess/PopupSpecialCashbackOffer.vue' /* webpackChunkName: "components/checkout-checkout-success-popup-special-cashback-offer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckoutSuccessSpecialCashbackOffer = () => import('../../components/Checkout/CheckoutSuccess/SpecialCashbackOffer.vue' /* webpackChunkName: "components/checkout-checkout-success-special-cashback-offer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditAdditionalLoanNote = () => import('../../components/Checkout/Credit/AdditionalLoanNote.vue' /* webpackChunkName: "components/checkout-credit-additional-loan-note" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditBankOffersInfo = () => import('../../components/Checkout/Credit/BankOffersInfo.vue' /* webpackChunkName: "components/checkout-credit-bank-offers-info" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditBox = () => import('../../components/Checkout/Credit/Box.vue' /* webpackChunkName: "components/checkout-credit-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditChoiceLoanInstallments = () => import('../../components/Checkout/Credit/ChoiceLoanInstallments.vue' /* webpackChunkName: "components/checkout-credit-choice-loan-installments" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditConditionsFirstCredit = () => import('../../components/Checkout/Credit/ConditionsFirstCredit.vue' /* webpackChunkName: "components/checkout-credit-conditions-first-credit" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditConditionOption = () => import('../../components/Checkout/Credit/CreditConditionOption.vue' /* webpackChunkName: "components/checkout-credit-condition-option" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditConditions = () => import('../../components/Checkout/Credit/CreditConditions.vue' /* webpackChunkName: "components/checkout-credit-conditions" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditKapitalBankCreditConditions = () => import('../../components/Checkout/Credit/KapitalBankCreditConditions.vue' /* webpackChunkName: "components/checkout-credit-kapital-bank-credit-conditions" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditLoanMultiOrder = () => import('../../components/Checkout/Credit/LoanMultiOrder.vue' /* webpackChunkName: "components/checkout-credit-loan-multi-order" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalCheckOTPCredit = () => import('../../components/Checkout/Credit/ModalCheckOTPCredit.vue' /* webpackChunkName: "components/checkout-credit-modal-check-o-t-p-credit" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalErrorLoanApplications = () => import('../../components/Checkout/Credit/ModalErrorLoanApplications.vue' /* webpackChunkName: "components/checkout-credit-modal-error-loan-applications" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalFINCode = () => import('../../components/Checkout/Credit/ModalFINCode.vue' /* webpackChunkName: "components/checkout-credit-modal-f-i-n-code" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalPhoneNumberBlocked = () => import('../../components/Checkout/Credit/ModalPhoneNumberBlocked.vue' /* webpackChunkName: "components/checkout-credit-modal-phone-number-blocked" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditMoreAboutLoanKapitalBank = () => import('../../components/Checkout/Credit/MoreAboutLoanKapitalBank.vue' /* webpackChunkName: "components/checkout-credit-more-about-loan-kapital-bank" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditStartWaitingBank = () => import('../../components/Checkout/Credit/StartWaitingBank.vue' /* webpackChunkName: "components/checkout-credit-start-waiting-bank" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditWarningModalLoanApplicationCreated = () => import('../../components/Checkout/Credit/WarningModalLoanApplicationCreated.vue' /* webpackChunkName: "components/checkout-credit-warning-modal-loan-application-created" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditWarningModalLoanApplicationCreatedLine = () => import('../../components/Checkout/Credit/WarningModalLoanApplicationCreatedLine.vue' /* webpackChunkName: "components/checkout-credit-warning-modal-loan-application-created-line" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddress = () => import('../../components/Checkout/Delivery/Address.vue' /* webpackChunkName: "components/checkout-delivery-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddressManual = () => import('../../components/Checkout/Delivery/AddressManual.vue' /* webpackChunkName: "components/checkout-delivery-address-manual" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAzerpoctCitySelect = () => import('../../components/Checkout/Delivery/AzerpoctCitySelect.vue' /* webpackChunkName: "components/checkout-delivery-azerpoct-city-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAzerpoctPudoSelect = () => import('../../components/Checkout/Delivery/AzerpoctPudoSelect.vue' /* webpackChunkName: "components/checkout-delivery-azerpoct-pudo-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryBox = () => import('../../components/Checkout/Delivery/Box.vue' /* webpackChunkName: "components/checkout-delivery-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryCost = () => import('../../components/Checkout/Delivery/Cost.vue' /* webpackChunkName: "components/checkout-delivery-cost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryCostAzerpoct = () => import('../../components/Checkout/Delivery/CostAzerpoct.vue' /* webpackChunkName: "components/checkout-delivery-cost-azerpoct" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryEmployeeCode = () => import('../../components/Checkout/Delivery/EmployeeCode.vue' /* webpackChunkName: "components/checkout-delivery-employee-code" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryErrorPaymentMethodExceptions = () => import('../../components/Checkout/Delivery/ErrorPaymentMethodExceptions.vue' /* webpackChunkName: "components/checkout-delivery-error-payment-method-exceptions" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryExpress = () => import('../../components/Checkout/Delivery/Express.vue' /* webpackChunkName: "components/checkout-delivery-express" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryFittingInfoBox = () => import('../../components/Checkout/Delivery/FittingInfoBox.vue' /* webpackChunkName: "components/checkout-delivery-fitting-info-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryFreeCost = () => import('../../components/Checkout/Delivery/FreeCost.vue' /* webpackChunkName: "components/checkout-delivery-free-cost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryPudo = () => import('../../components/Checkout/Delivery/Pudo.vue' /* webpackChunkName: "components/checkout-delivery-pudo" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryPudoAzerpoct = () => import('../../components/Checkout/Delivery/PudoAzerpoct.vue' /* webpackChunkName: "components/checkout-delivery-pudo-azerpoct" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryPudoSelect = () => import('../../components/Checkout/Delivery/PudoSelect.vue' /* webpackChunkName: "components/checkout-delivery-pudo-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryTimeInfoExpress = () => import('../../components/Checkout/Delivery/TimeInfoExpress.vue' /* webpackChunkName: "components/checkout-delivery-time-info-express" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryTimeInfoPudo = () => import('../../components/Checkout/Delivery/TimeInfoPudo.vue' /* webpackChunkName: "components/checkout-delivery-time-info-pudo" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryTimeInfoPudoAzerpost = () => import('../../components/Checkout/Delivery/TimeInfoPudoAzerpost.vue' /* webpackChunkName: "components/checkout-delivery-time-info-pudo-azerpost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalsCRModal = () => import('../../components/Checkout/Modals/CRModal.vue' /* webpackChunkName: "components/checkout-modals-c-r-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalDateTimeNotSelected = () => import('../../components/Checkout/Modals/CheckoutModalDateTimeNotSelected.vue' /* webpackChunkName: "components/checkout-modal-date-time-not-selected" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutAddressListLoading = () => import('../../components/Checkout/NewCheckout/AddressListLoading.vue' /* webpackChunkName: "components/checkout-new-checkout-address-list-loading" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutApplyToAllOrdersModal = () => import('../../components/Checkout/NewCheckout/ApplyToAllOrdersModal.vue' /* webpackChunkName: "components/checkout-new-checkout-apply-to-all-orders-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPageRadioCard = () => import('../../components/Checkout/NewCheckout/CheckoutPageRadioCard.vue' /* webpackChunkName: "components/checkout-page-radio-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutIndexCheckoutPage = () => import('../../components/Checkout/NewCheckout/IndexCheckoutPage.vue' /* webpackChunkName: "components/checkout-new-checkout-index-checkout-page" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutLineItem = () => import('../../components/Checkout/NewCheckout/LineItem.vue' /* webpackChunkName: "components/checkout-new-checkout-line-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutLoading = () => import('../../components/Checkout/NewCheckout/Loading.vue' /* webpackChunkName: "components/checkout-new-checkout-loading" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutOrders = () => import('../../components/Checkout/NewCheckout/Orders.vue' /* webpackChunkName: "components/checkout-new-checkout-orders" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutTimeFramesNotAvailablePopup = () => import('../../components/Checkout/NewCheckout/TimeFramesNotAvailablePopup.vue' /* webpackChunkName: "components/checkout-new-checkout-time-frames-not-available-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderAdditionalInfo = () => import('../../components/Checkout/Order/AdditionalInfo.vue' /* webpackChunkName: "components/checkout-order-additional-info" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderItem = () => import('../../components/Checkout/Order/Item.vue' /* webpackChunkName: "components/checkout-order-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentBox = () => import('../../components/Checkout/Payment/Box.vue' /* webpackChunkName: "components/checkout-payment-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentCardsList = () => import('../../components/Checkout/Payment/CardsList.vue' /* webpackChunkName: "components/checkout-payment-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentGoToCreditPayment = () => import('../../components/Checkout/Payment/GoToCreditPayment.vue' /* webpackChunkName: "components/checkout-payment-go-to-credit-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentInfoEcomKbInstallment = () => import('../../components/Checkout/Payment/InfoEcomKbInstallment.vue' /* webpackChunkName: "components/checkout-payment-info-ecom-kb-installment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentMethodCardEcomKbInstallment = () => import('../../components/Checkout/Payment/MethodCardEcomKbInstallment.vue' /* webpackChunkName: "components/checkout-payment-method-card-ecom-kb-installment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentProductLogo = () => import('../../components/Checkout/Payment/ProductLogo.vue' /* webpackChunkName: "components/checkout-payment-product-logo" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPromoCodeCashbackPromoCodeInfo = () => import('../../components/Checkout/PromoCode/CashbackPromoCodeInfo.vue' /* webpackChunkName: "components/checkout-promo-code-cashback-promo-code-info" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPromoCodeErrorBlockCashback = () => import('../../components/Checkout/PromoCode/PromoCodeErrorBlockCashback.vue' /* webpackChunkName: "components/checkout-promo-code-error-block-cashback" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPromoCodeInfoBlockCashback = () => import('../../components/Checkout/PromoCode/PromoCodeInfoBlockCashback.vue' /* webpackChunkName: "components/checkout-promo-code-info-block-cashback" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPromoCodeInfoBlockDiscount = () => import('../../components/Checkout/PromoCode/PromoCodeInfoBlockDiscount.vue' /* webpackChunkName: "components/checkout-promo-code-info-block-discount" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPromoCodePromocode = () => import('../../components/Checkout/PromoCode/Promocode.vue' /* webpackChunkName: "components/checkout-promo-code-promocode" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPromoCodeSwitchersBlock = () => import('../../components/Checkout/PromoCode/SwitchersBlock.vue' /* webpackChunkName: "components/checkout-promo-code-switchers-block" */).then(c => wrapFunctional(c.default || c))
export const HeaderAppHeaderCartLink = () => import('../../components/Header/AppHeader/CartLink.vue' /* webpackChunkName: "components/header-app-header-cart-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderAppHeaderCompareLink = () => import('../../components/Header/AppHeader/CompareLink.vue' /* webpackChunkName: "components/header-app-header-compare-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderAppHeaderFavoriteLink = () => import('../../components/Header/AppHeader/FavoriteLink.vue' /* webpackChunkName: "components/header-app-header-favorite-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderAppHeaderSearchButton = () => import('../../components/Header/AppHeader/SearchButton.vue' /* webpackChunkName: "components/header-app-header-search-button" */).then(c => wrapFunctional(c.default || c))
export const HeaderGeoButton = () => import('../../components/Header/GeoLocation/GeoButton.vue' /* webpackChunkName: "components/header-geo-button" */).then(c => wrapFunctional(c.default || c))
export const HeaderGeoLocation = () => import('../../components/Header/GeoLocation/GeoLocation.vue' /* webpackChunkName: "components/header-geo-location" */).then(c => wrapFunctional(c.default || c))
export const HeaderGeoLocationBox = () => import('../../components/Header/GeoLocation/GeoLocationBox.vue' /* webpackChunkName: "components/header-geo-location-box" */).then(c => wrapFunctional(c.default || c))
export const HeaderGeoLocationItem = () => import('../../components/Header/GeoLocation/GeoLocationItem.vue' /* webpackChunkName: "components/header-geo-location-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderHamburger = () => import('../../components/Header/Hamburger/Hamburger.vue' /* webpackChunkName: "components/header-hamburger" */).then(c => wrapFunctional(c.default || c))
export const HeaderHamburgerButton = () => import('../../components/Header/Hamburger/HamburgerButton.vue' /* webpackChunkName: "components/header-hamburger-button" */).then(c => wrapFunctional(c.default || c))
export const HeaderHamburgerDropdown = () => import('../../components/Header/Hamburger/HamburgerDropdown.vue' /* webpackChunkName: "components/header-hamburger-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderHeaderUserGuest = () => import('../../components/Header/HeaderUser/Guest.vue' /* webpackChunkName: "components/header-header-user-guest" */).then(c => wrapFunctional(c.default || c))
export const HeaderUser = () => import('../../components/Header/HeaderUser/HeaderUser.vue' /* webpackChunkName: "components/header-user" */).then(c => wrapFunctional(c.default || c))
export const HeaderHeaderUserLoggedIn = () => import('../../components/Header/HeaderUser/LoggedIn.vue' /* webpackChunkName: "components/header-header-user-logged-in" */).then(c => wrapFunctional(c.default || c))
export const HeaderLangSelect = () => import('../../components/Header/LangSelect/LangSelect.vue' /* webpackChunkName: "components/header-lang-select" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchCategoryItem = () => import('../../components/Header/QuickSearch/CategoryItem.vue' /* webpackChunkName: "components/header-quick-search-category-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchEmptyItem = () => import('../../components/Header/QuickSearch/EmptyItem.vue' /* webpackChunkName: "components/header-quick-search-empty-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchKeywordItem = () => import('../../components/Header/QuickSearch/KeywordItem.vue' /* webpackChunkName: "components/header-quick-search-keyword-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchMerchantsItem = () => import('../../components/Header/QuickSearch/MerchantsItem.vue' /* webpackChunkName: "components/header-quick-search-merchants-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchPosAddress = () => import('../../components/Header/QuickSearch/PosAddress.vue' /* webpackChunkName: "components/header-quick-search-pos-address" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearch = () => import('../../components/Header/QuickSearch/QuickSearch.vue' /* webpackChunkName: "components/header-quick-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchBy = () => import('../../components/Header/QuickSearch/QuickSearchBy.vue' /* webpackChunkName: "components/header-quick-search-by" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchByMobile = () => import('../../components/Header/QuickSearch/QuickSearchByMobile.vue' /* webpackChunkName: "components/header-quick-search-by-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchBrandItem = () => import('../../components/Header/QuickSearch/SearchBrandItem.vue' /* webpackChunkName: "components/header-quick-search-brand-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchCompanyItem = () => import('../../components/Header/QuickSearch/SearchCompanyItem.vue' /* webpackChunkName: "components/header-quick-search-company-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchDropdown = () => import('../../components/Header/QuickSearch/SearchDropdown.vue' /* webpackChunkName: "components/header-quick-search-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchHistoryBlock = () => import('../../components/Header/QuickSearch/SearchHistoryBlock.vue' /* webpackChunkName: "components/header-quick-search-history-block" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchItemLocation = () => import('../../components/Header/QuickSearch/SearchItemLocation.vue' /* webpackChunkName: "components/header-quick-search-item-location" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchItemLogo = () => import('../../components/Header/QuickSearch/SearchItemLogo.vue' /* webpackChunkName: "components/header-quick-search-item-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchMPCategoryItem = () => import('../../components/Header/QuickSearch/SearchMPCategoryItem.vue' /* webpackChunkName: "components/header-quick-search-m-p-category-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchPopularItem = () => import('../../components/Header/QuickSearch/SearchPopularItem.vue' /* webpackChunkName: "components/header-quick-search-popular-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchProductItem = () => import('../../components/Header/QuickSearch/SearchProductItem.vue' /* webpackChunkName: "components/header-quick-search-product-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderQuickSearchTagItem = () => import('../../components/Header/QuickSearch/SearchTagItem.vue' /* webpackChunkName: "components/header-quick-search-tag-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderTopHeaderCallCenter = () => import('../../components/Header/TopHeader/CallCenter.vue' /* webpackChunkName: "components/header-top-header-call-center" */).then(c => wrapFunctional(c.default || c))
export const MarketCart = () => import('../../components/Market/Cart/Cart.vue' /* webpackChunkName: "components/market-cart" */).then(c => wrapFunctional(c.default || c))
export const MarketCartBox = () => import('../../components/Market/Cart/CartBox.vue' /* webpackChunkName: "components/market-cart-box" */).then(c => wrapFunctional(c.default || c))
export const MarketCartCreditBtn = () => import('../../components/Market/Cart/CartCreditBtn.vue' /* webpackChunkName: "components/market-cart-credit-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketCartInstallment = () => import('../../components/Market/Cart/CartInstallment.vue' /* webpackChunkName: "components/market-cart-installment" */).then(c => wrapFunctional(c.default || c))
export const MarketCartItemAlternativeSlider = () => import('../../components/Market/Cart/CartItemAlternativeSlider.vue' /* webpackChunkName: "components/market-cart-item-alternative-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketCartItemSellProducts = () => import('../../components/Market/Cart/CartItemSellProducts.vue' /* webpackChunkName: "components/market-cart-item-sell-products" */).then(c => wrapFunctional(c.default || c))
export const MarketCartItemSeller = () => import('../../components/Market/Cart/CartItemSeller.vue' /* webpackChunkName: "components/market-cart-item-seller" */).then(c => wrapFunctional(c.default || c))
export const MarketCartItemSellerTotal = () => import('../../components/Market/Cart/CartItemSellerTotal.vue' /* webpackChunkName: "components/market-cart-item-seller-total" */).then(c => wrapFunctional(c.default || c))
export const MarketCartItems = () => import('../../components/Market/Cart/CartItems.vue' /* webpackChunkName: "components/market-cart-items" */).then(c => wrapFunctional(c.default || c))
export const MarketCartLinkAnonim = () => import('../../components/Market/Cart/CartLinkAnonim.vue' /* webpackChunkName: "components/market-cart-link-anonim" */).then(c => wrapFunctional(c.default || c))
export const MarketCartLinkRegistered = () => import('../../components/Market/Cart/CartLinkRegistered.vue' /* webpackChunkName: "components/market-cart-link-registered" */).then(c => wrapFunctional(c.default || c))
export const MarketCartProduct = () => import('../../components/Market/Cart/CartProduct.vue' /* webpackChunkName: "components/market-cart-product" */).then(c => wrapFunctional(c.default || c))
export const MarketCartStockProductOffers = () => import('../../components/Market/Cart/CartStockProductOffers.vue' /* webpackChunkName: "components/market-cart-stock-product-offers" */).then(c => wrapFunctional(c.default || c))
export const MarketCartSummary = () => import('../../components/Market/Cart/CartSummary.vue' /* webpackChunkName: "components/market-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const MarketCartSummaryDeliveryFree = () => import('../../components/Market/Cart/CartSummaryDeliveryFree.vue' /* webpackChunkName: "components/market-cart-summary-delivery-free" */).then(c => wrapFunctional(c.default || c))
export const MarketCartSummaryInfo = () => import('../../components/Market/Cart/CartSummaryInfo.vue' /* webpackChunkName: "components/market-cart-summary-info" */).then(c => wrapFunctional(c.default || c))
export const MarketCartCheckoutOrderBtn = () => import('../../components/Market/Cart/CheckoutOrderBtn.vue' /* webpackChunkName: "components/market-cart-checkout-order-btn" */).then(c => wrapFunctional(c.default || c))
export const MarketCartDeliveryDate = () => import('../../components/Market/Cart/DeliveryDate.vue' /* webpackChunkName: "components/market-cart-delivery-date" */).then(c => wrapFunctional(c.default || c))
export const MarketCartEmptyCart = () => import('../../components/Market/Cart/EmptyCart.vue' /* webpackChunkName: "components/market-cart-empty-cart" */).then(c => wrapFunctional(c.default || c))
export const MarketCartGroceryCalculateFreeDelivery = () => import('../../components/Market/Cart/GroceryCalculateFreeDelivery.vue' /* webpackChunkName: "components/market-cart-grocery-calculate-free-delivery" */).then(c => wrapFunctional(c.default || c))
export const MarketCartGroceryFreeDeliveryCart = () => import('../../components/Market/Cart/GroceryFreeDeliveryCart.vue' /* webpackChunkName: "components/market-cart-grocery-free-delivery-cart" */).then(c => wrapFunctional(c.default || c))
export const MarketCartMayInterest = () => import('../../components/Market/Cart/MayInterest.vue' /* webpackChunkName: "components/market-cart-may-interest" */).then(c => wrapFunctional(c.default || c))
export const MarketCartMinQtyWarningPopup = () => import('../../components/Market/Cart/MinQtyWarningPopup.vue' /* webpackChunkName: "components/market-cart-min-qty-warning-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketCartPopupAvailabilityGoodsSpecified = () => import('../../components/Market/Cart/PopupAvailabilityGoodsSpecified.vue' /* webpackChunkName: "components/market-cart-popup-availability-goods-specified" */).then(c => wrapFunctional(c.default || c))
export const MarketCartPopupCartMinAmount = () => import('../../components/Market/Cart/PopupCartMinAmount.vue' /* webpackChunkName: "components/market-cart-popup-cart-min-amount" */).then(c => wrapFunctional(c.default || c))
export const MarketCartProductQuantity = () => import('../../components/Market/Cart/ProductQuantity.vue' /* webpackChunkName: "components/market-cart-product-quantity" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareLinkAnonym = () => import('../../components/Market/Compare/CompareLinkAnonym.vue' /* webpackChunkName: "components/market-compare-link-anonym" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareLinkRegistered = () => import('../../components/Market/Compare/CompareLinkRegistered.vue' /* webpackChunkName: "components/market-compare-link-registered" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareProductForTable = () => import('../../components/Market/Compare/CompareProductForTable.vue' /* webpackChunkName: "components/market-compare-product-for-table" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareProductItem = () => import('../../components/Market/Compare/CompareProductItem.vue' /* webpackChunkName: "components/market-compare-product-item" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareProducts = () => import('../../components/Market/Compare/CompareProducts.vue' /* webpackChunkName: "components/market-compare-products" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareRegisterPopup = () => import('../../components/Market/Compare/CompareRegisterPopup.vue' /* webpackChunkName: "components/market-compare-register-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareCompares = () => import('../../components/Market/Compare/Compares.vue' /* webpackChunkName: "components/market-compare-compares" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareComparesMobile = () => import('../../components/Market/Compare/ComparesMobile.vue' /* webpackChunkName: "components/market-compare-compares-mobile" */).then(c => wrapFunctional(c.default || c))
export const MarketCompareMPAddToCompare = () => import('../../components/Market/Compare/MPAddToCompare.vue' /* webpackChunkName: "components/market-compare-m-p-add-to-compare" */).then(c => wrapFunctional(c.default || c))
export const MarketCreditBuyOnCredit = () => import('../../components/Market/Credit/BuyOnCredit.vue' /* webpackChunkName: "components/market-credit-buy-on-credit" */).then(c => wrapFunctional(c.default || c))
export const MarketFavoritesFavoriteLinkAnonym = () => import('../../components/Market/Favorites/FavoriteLinkAnonym.vue' /* webpackChunkName: "components/market-favorites-favorite-link-anonym" */).then(c => wrapFunctional(c.default || c))
export const MarketFavoritesFavoriteLinkRegistered = () => import('../../components/Market/Favorites/FavoriteLinkRegistered.vue' /* webpackChunkName: "components/market-favorites-favorite-link-registered" */).then(c => wrapFunctional(c.default || c))
export const MarketFavoritesFavoriteProducts = () => import('../../components/Market/Favorites/FavoriteProducts.vue' /* webpackChunkName: "components/market-favorites-favorite-products" */).then(c => wrapFunctional(c.default || c))
export const MarketFavoritesFavoriteRegisterPopup = () => import('../../components/Market/Favorites/FavoriteRegisterPopup.vue' /* webpackChunkName: "components/market-favorites-favorite-register-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketFavoritesMPAddToFavorites = () => import('../../components/Market/Favorites/MPAddToFavorites.vue' /* webpackChunkName: "components/market-favorites-m-p-add-to-favorites" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMPClearFilters = () => import('../../components/Market/Filters/MPClearFilters.vue' /* webpackChunkName: "components/market-filters-m-p-clear-filters" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMPFilter = () => import('../../components/Market/Filters/MPFilter.vue' /* webpackChunkName: "components/market-filters-m-p-filter" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMPFilterManufacturer = () => import('../../components/Market/Filters/MPFilterManufacturer.vue' /* webpackChunkName: "components/market-filters-m-p-filter-manufacturer" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMPRangeSlider = () => import('../../components/Market/Filters/MPRangeSlider.vue' /* webpackChunkName: "components/market-filters-m-p-range-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMpFilterTypeBoolean = () => import('../../components/Market/Filters/MpFilterTypeBoolean.vue' /* webpackChunkName: "components/market-filters-mp-filter-type-boolean" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMpFilterTypeMulti = () => import('../../components/Market/Filters/MpFilterTypeMulti.vue' /* webpackChunkName: "components/market-filters-mp-filter-type-multi" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersMpFilterTypeRange = () => import('../../components/Market/Filters/MpFilterTypeRange.vue' /* webpackChunkName: "components/market-filters-mp-filter-type-range" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersSelectedFilters = () => import('../../components/Market/Filters/SelectedFilters.vue' /* webpackChunkName: "components/market-filters-selected-filters" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingApplications = () => import('../../components/Market/Landing/Applications.vue' /* webpackChunkName: "components/market-landing-applications" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingCategories = () => import('../../components/Market/Landing/Categories.vue' /* webpackChunkName: "components/market-landing-categories" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingHeader = () => import('../../components/Market/Landing/Header.vue' /* webpackChunkName: "components/market-landing-header" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingIndexSeo = () => import('../../components/Market/Landing/IndexSeo.vue' /* webpackChunkName: "components/market-landing-index-seo" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingLoyaltyPartner = () => import('../../components/Market/Landing/LoyaltyPartner.vue' /* webpackChunkName: "components/market-landing-loyalty-partner" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingLoyaltyPartners = () => import('../../components/Market/Landing/LoyaltyPartners.vue' /* webpackChunkName: "components/market-landing-loyalty-partners" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingLoyatlyProgram = () => import('../../components/Market/Landing/LoyatlyProgram.vue' /* webpackChunkName: "components/market-landing-loyatly-program" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingBirKart = () => import('../../components/Market/Landing/MPLandingBirKart.vue' /* webpackChunkName: "components/market-landing-m-p-landing-bir-kart" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingCartItems = () => import('../../components/Market/Landing/MPLandingCartItems.vue' /* webpackChunkName: "components/market-landing-m-p-landing-cart-items" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingIconBadges = () => import('../../components/Market/Landing/MPLandingIconBadges.vue' /* webpackChunkName: "components/market-landing-m-p-landing-icon-badges" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingLinkBadges = () => import('../../components/Market/Landing/MPLandingLinkBadges.vue' /* webpackChunkName: "components/market-landing-m-p-landing-link-badges" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingMenu = () => import('../../components/Market/Landing/MPLandingMenu.vue' /* webpackChunkName: "components/market-landing-m-p-landing-menu" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingPopularCategories = () => import('../../components/Market/Landing/MPLandingPopularCategories.vue' /* webpackChunkName: "components/market-landing-m-p-landing-popular-categories" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPLandingSlider = () => import('../../components/Market/Landing/MPLandingSlider.vue' /* webpackChunkName: "components/market-landing-m-p-landing-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMPRecommendedSlider404 = () => import('../../components/Market/Landing/MPRecommendedSlider404.vue' /* webpackChunkName: "components/market-landing-m-p-recommended-slider404" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingMegaDiscounts = () => import('../../components/Market/Landing/MegaDiscounts.vue' /* webpackChunkName: "components/market-landing-mega-discounts" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingNewItems = () => import('../../components/Market/Landing/NewItems.vue' /* webpackChunkName: "components/market-landing-new-items" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingPersonalRecommendations = () => import('../../components/Market/Landing/PersonalRecommendations.vue' /* webpackChunkName: "components/market-landing-personal-recommendations" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingPopularBrands = () => import('../../components/Market/Landing/PopularBrands.vue' /* webpackChunkName: "components/market-landing-popular-brands" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingPopularSellers = () => import('../../components/Market/Landing/PopularSellers.vue' /* webpackChunkName: "components/market-landing-popular-sellers" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingPromoBanner = () => import('../../components/Market/Landing/PromoBanner.vue' /* webpackChunkName: "components/market-landing-promo-banner" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingRecommended = () => import('../../components/Market/Landing/Recommended.vue' /* webpackChunkName: "components/market-landing-recommended" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingSaleDay = () => import('../../components/Market/Landing/SaleDay.vue' /* webpackChunkName: "components/market-landing-sale-day" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingTopProducts = () => import('../../components/Market/Landing/TopProducts.vue' /* webpackChunkName: "components/market-landing-top-products" */).then(c => wrapFunctional(c.default || c))
export const MarketLandingTopSeoTags = () => import('../../components/Market/Landing/TopSeoTags.vue' /* webpackChunkName: "components/market-landing-top-seo-tags" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupCartBlock = () => import('../../components/Market/PopupCart/Block.vue' /* webpackChunkName: "components/market-popup-cart-block" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupCartMobile = () => import('../../components/Market/PopupCart/Mobile.vue' /* webpackChunkName: "components/market-popup-cart-mobile" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsErrorBirId = () => import('../../components/Market/Popups/ErrorBirId.vue' /* webpackChunkName: "components/market-popups-error-bir-id" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsGlobalPopups = () => import('../../components/Market/Popups/GlobalPopups.vue' /* webpackChunkName: "components/market-popups-global-popups" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsGrosseryCart = () => import('../../components/Market/Popups/GrosseryCart.vue' /* webpackChunkName: "components/market-popups-grossery-cart" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsPopupAppMarket = () => import('../../components/Market/Popups/PopupAppMarket.vue' /* webpackChunkName: "components/market-popups-popup-app-market" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsPopupCompareMobile = () => import('../../components/Market/Popups/PopupCompareMobile.vue' /* webpackChunkName: "components/market-popups-popup-compare-mobile" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsPopupFavoriteMobile = () => import('../../components/Market/Popups/PopupFavoriteMobile.vue' /* webpackChunkName: "components/market-popups-popup-favorite-mobile" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsPopupRegistrationRequired = () => import('../../components/Market/Popups/PopupRegistrationRequired.vue' /* webpackChunkName: "components/market-popups-popup-registration-required" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsPopupReviewLoginUser = () => import('../../components/Market/Popups/PopupReviewLoginUser.vue' /* webpackChunkName: "components/market-popups-popup-review-login-user" */).then(c => wrapFunctional(c.default || c))
export const MarketPopupsPopupSomethingWentWrong = () => import('../../components/Market/Popups/PopupSomethingWentWrong.vue' /* webpackChunkName: "components/market-popups-popup-something-went-wrong" */).then(c => wrapFunctional(c.default || c))
export const MarketProductLotteryPopup = () => import('../../components/Market/Product/LotteryPopup.vue' /* webpackChunkName: "components/market-product-lottery-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketProductLotteryPopupSubscribe = () => import('../../components/Market/Product/LotteryPopupSubscribe.vue' /* webpackChunkName: "components/market-product-lottery-popup-subscribe" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPAddToBasket = () => import('../../components/Market/Product/MPAddToBasket.vue' /* webpackChunkName: "components/market-product-m-p-add-to-basket" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPAvailability = () => import('../../components/Market/Product/MPAvailability.vue' /* webpackChunkName: "components/market-product-m-p-availability" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPAvailableRegisterPopup = () => import('../../components/Market/Product/MPAvailableRegisterPopup.vue' /* webpackChunkName: "components/market-product-m-p-available-register-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPBulkPurchasePopup = () => import('../../components/Market/Product/MPBulkPurchasePopup.vue' /* webpackChunkName: "components/market-product-m-p-bulk-purchase-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPCashback = () => import('../../components/Market/Product/MPCashback.vue' /* webpackChunkName: "components/market-product-m-p-cashback" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPDiscount = () => import('../../components/Market/Product/MPDiscount.vue' /* webpackChunkName: "components/market-product-m-p-discount" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPInstallment = () => import('../../components/Market/Product/MPInstallment.vue' /* webpackChunkName: "components/market-product-m-p-installment" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPLogo = () => import('../../components/Market/Product/MPLogo.vue' /* webpackChunkName: "components/market-product-m-p-logo" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPOfferPrice = () => import('../../components/Market/Product/MPOfferPrice.vue' /* webpackChunkName: "components/market-product-m-p-offer-price" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPOtherOffersPopup = () => import('../../components/Market/Product/MPOtherOffersPopup.vue' /* webpackChunkName: "components/market-product-m-p-other-offers-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPPreOrderConfirmPopup = () => import('../../components/Market/Product/MPPreOrderConfirmPopup.vue' /* webpackChunkName: "components/market-product-m-p-pre-order-confirm-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPPrice = () => import('../../components/Market/Product/MPPrice.vue' /* webpackChunkName: "components/market-product-m-p-price" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPProductLabels = () => import('../../components/Market/Product/MPProductLabels.vue' /* webpackChunkName: "components/market-product-m-p-product-labels" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPProductMerchant = () => import('../../components/Market/Product/MPProductMerchant.vue' /* webpackChunkName: "components/market-product-m-p-product-merchant" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPProductSkeleton = () => import('../../components/Market/Product/MPProductSkeleton.vue' /* webpackChunkName: "components/market-product-m-p-product-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPQuantityDiscounts = () => import('../../components/Market/Product/MPQuantityDiscounts.vue' /* webpackChunkName: "components/market-product-m-p-quantity-discounts" */).then(c => wrapFunctional(c.default || c))
export const MarketProductMPTitle = () => import('../../components/Market/Product/MPTitle.vue' /* webpackChunkName: "components/market-product-m-p-title" */).then(c => wrapFunctional(c.default || c))
export const MarketProductOutletInfo = () => import('../../components/Market/Product/OutletInfo.vue' /* webpackChunkName: "components/market-product-outlet-info" */).then(c => wrapFunctional(c.default || c))
export const MarketProductSeeAlso = () => import('../../components/Market/Product/ProductSeeAlso.vue' /* webpackChunkName: "components/market-product-see-also" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPBrandLinks = () => import('../../components/Market/Products/MPBrandLinks.vue' /* webpackChunkName: "components/market-products-m-p-brand-links" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPChoiceVariationsProduct = () => import('../../components/Market/Products/MPChoiceVariationsProduct.vue' /* webpackChunkName: "components/market-products-m-p-choice-variations-product" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPFilterCategory = () => import('../../components/Market/Products/MPFilterCategory.vue' /* webpackChunkName: "components/market-products-m-p-filter-category" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPFilters = () => import('../../components/Market/Products/MPFilters.vue' /* webpackChunkName: "components/market-products-m-p-filters" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPFiltersCategoryMegaDiscounts = () => import('../../components/Market/Products/MPFiltersCategoryMegaDiscounts.vue' /* webpackChunkName: "components/market-products-m-p-filters-category-mega-discounts" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPMetaProductItem = () => import('../../components/Market/Products/MPMetaProductItem.vue' /* webpackChunkName: "components/market-products-m-p-meta-product-item" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductItem = () => import('../../components/Market/Products/MPProductItem.vue' /* webpackChunkName: "components/market-products-m-p-product-item" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductPagination = () => import('../../components/Market/Products/MPProductPagination.vue' /* webpackChunkName: "components/market-products-m-p-product-pagination" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProducts = () => import('../../components/Market/Products/MPProducts.vue' /* webpackChunkName: "components/market-products-m-p-products" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductsLeftovers = () => import('../../components/Market/Products/MPProductsLeftovers.vue' /* webpackChunkName: "components/market-products-m-p-products-leftovers" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductsList = () => import('../../components/Market/Products/MPProductsList.vue' /* webpackChunkName: "components/market-products-m-p-products-list" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductsListBanners = () => import('../../components/Market/Products/MPProductsListBanners.vue' /* webpackChunkName: "components/market-products-m-p-products-list-banners" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductsShowMore = () => import('../../components/Market/Products/MPProductsShowMore.vue' /* webpackChunkName: "components/market-products-m-p-products-show-more" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPProductsWithoutBanners = () => import('../../components/Market/Products/MPProductsWithoutBanners.vue' /* webpackChunkName: "components/market-products-m-p-products-without-banners" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsMPSortby = () => import('../../components/Market/Products/MPSortby.vue' /* webpackChunkName: "components/market-products-m-p-sortby" */).then(c => wrapFunctional(c.default || c))
export const MarketProductsNewMProductItem = () => import('../../components/Market/Products/NewMProductItem.vue' /* webpackChunkName: "components/market-products-new-m-product-item" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductBirkartCashback = () => import('../../components/Market/SingleProduct/BirkartCashback.vue' /* webpackChunkName: "components/market-single-product-birkart-cashback" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductBuyOneClick = () => import('../../components/Market/SingleProduct/BuyOneClick.vue' /* webpackChunkName: "components/market-single-product-buy-one-click" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductCompareButton = () => import('../../components/Market/SingleProduct/CompareButton.vue' /* webpackChunkName: "components/market-single-product-compare-button" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductCompareLink = () => import('../../components/Market/SingleProduct/CompareLink.vue' /* webpackChunkName: "components/market-single-product-compare-link" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductCrossBorderDisclaimer = () => import('../../components/Market/SingleProduct/CrossBorderDisclaimer.vue' /* webpackChunkName: "components/market-single-product-cross-border-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPAddToCardSnack = () => import('../../components/Market/SingleProduct/MPAddToCardSnack.vue' /* webpackChunkName: "components/market-single-product-m-p-add-to-card-snack" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPAttributeColor = () => import('../../components/Market/SingleProduct/MPAttributeColor.vue' /* webpackChunkName: "components/market-single-product-m-p-attribute-color" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPAttributeSize = () => import('../../components/Market/SingleProduct/MPAttributeSize.vue' /* webpackChunkName: "components/market-single-product-m-p-attribute-size" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPDelivery = () => import('../../components/Market/SingleProduct/MPDelivery.vue' /* webpackChunkName: "components/market-single-product-m-p-delivery" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPDeliveryPlanDate = () => import('../../components/Market/SingleProduct/MPDeliveryPlanDate.vue' /* webpackChunkName: "components/market-single-product-m-p-delivery-plan-date" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPFittingAllowed = () => import('../../components/Market/SingleProduct/MPFittingAllowed.vue' /* webpackChunkName: "components/market-single-product-m-p-fitting-allowed" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPGrayBox = () => import('../../components/Market/SingleProduct/MPGrayBox.vue' /* webpackChunkName: "components/market-single-product-m-p-gray-box" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPMobileAddToCardSnack = () => import('../../components/Market/SingleProduct/MPMobileAddToCardSnack.vue' /* webpackChunkName: "components/market-single-product-m-p-mobile-add-to-card-snack" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPMobileAttributes = () => import('../../components/Market/SingleProduct/MPMobileAttributes.vue' /* webpackChunkName: "components/market-single-product-m-p-mobile-attributes" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPPayment = () => import('../../components/Market/SingleProduct/MPPayment.vue' /* webpackChunkName: "components/market-single-product-m-p-payment" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPPaymentDelivery = () => import('../../components/Market/SingleProduct/MPPaymentDelivery.vue' /* webpackChunkName: "components/market-single-product-m-p-payment-delivery" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProduct = () => import('../../components/Market/SingleProduct/MPProduct.vue' /* webpackChunkName: "components/market-single-product-m-p-product" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductAdultMessage = () => import('../../components/Market/SingleProduct/MPProductAdultMessage.vue' /* webpackChunkName: "components/market-single-product-m-p-product-adult-message" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductAdvertisingBlock = () => import('../../components/Market/SingleProduct/MPProductAdvertisingBlock.vue' /* webpackChunkName: "components/market-single-product-m-p-product-advertising-block" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductAlternativeSlider = () => import('../../components/Market/SingleProduct/MPProductAlternativeSlider.vue' /* webpackChunkName: "components/market-single-product-m-p-product-alternative-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductBreadcrumb = () => import('../../components/Market/SingleProduct/MPProductBreadcrumb.vue' /* webpackChunkName: "components/market-single-product-m-p-product-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductContent = () => import('../../components/Market/SingleProduct/MPProductContent.vue' /* webpackChunkName: "components/market-single-product-m-p-product-content" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductDescription = () => import('../../components/Market/SingleProduct/MPProductDescription.vue' /* webpackChunkName: "components/market-single-product-m-p-product-description" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductDescriptionAll = () => import('../../components/Market/SingleProduct/MPProductDescriptionAll.vue' /* webpackChunkName: "components/market-single-product-m-p-product-description-all" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductDescriptionContentFeatures = () => import('../../components/Market/SingleProduct/MPProductDescriptionContentFeatures.vue' /* webpackChunkName: "components/market-single-product-m-p-product-description-content-features" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductDescriptionContentInfo = () => import('../../components/Market/SingleProduct/MPProductDescriptionContentInfo.vue' /* webpackChunkName: "components/market-single-product-m-p-product-description-content-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductDescriptionTabs = () => import('../../components/Market/SingleProduct/MPProductDescriptionTabs.vue' /* webpackChunkName: "components/market-single-product-m-p-product-description-tabs" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductInfo = () => import('../../components/Market/SingleProduct/MPProductInfo.vue' /* webpackChunkName: "components/market-single-product-m-p-product-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductInstallment = () => import('../../components/Market/SingleProduct/MPProductInstallment.vue' /* webpackChunkName: "components/market-single-product-m-p-product-installment" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductLotteryBadge = () => import('../../components/Market/SingleProduct/MPProductLotteryBadge.vue' /* webpackChunkName: "components/market-single-product-m-p-product-lottery-badge" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductMainDesc = () => import('../../components/Market/SingleProduct/MPProductMainDesc.vue' /* webpackChunkName: "components/market-single-product-m-p-product-main-desc" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductOffer = () => import('../../components/Market/SingleProduct/MPProductOffer.vue' /* webpackChunkName: "components/market-single-product-m-p-product-offer" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductOffers = () => import('../../components/Market/SingleProduct/MPProductOffers.vue' /* webpackChunkName: "components/market-single-product-m-p-product-offers" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductPricesOtherSellers = () => import('../../components/Market/SingleProduct/MPProductPricesOtherSellers.vue' /* webpackChunkName: "components/market-single-product-m-p-product-prices-other-sellers" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductReviewTab = () => import('../../components/Market/SingleProduct/MPProductReviewTab.vue' /* webpackChunkName: "components/market-single-product-m-p-product-review-tab" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductSlider = () => import('../../components/Market/SingleProduct/MPProductSlider.vue' /* webpackChunkName: "components/market-single-product-m-p-product-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductVideo = () => import('../../components/Market/SingleProduct/MPProductVideo.vue' /* webpackChunkName: "components/market-single-product-m-p-product-video" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductVideoContentInfo = () => import('../../components/Market/SingleProduct/MPProductVideoContentInfo.vue' /* webpackChunkName: "components/market-single-product-m-p-product-video-content-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductVideoMobile = () => import('../../components/Market/SingleProduct/MPProductVideoMobile.vue' /* webpackChunkName: "components/market-single-product-m-p-product-video-mobile" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPProductWarningColor = () => import('../../components/Market/SingleProduct/MPProductWarningColor.vue' /* webpackChunkName: "components/market-single-product-m-p-product-warning-color" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPRecommended = () => import('../../components/Market/SingleProduct/MPRecommended.vue' /* webpackChunkName: "components/market-single-product-m-p-recommended" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPRecommendedSlider = () => import('../../components/Market/SingleProduct/MPRecommendedSlider.vue' /* webpackChunkName: "components/market-single-product-m-p-recommended-slider" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSellerInfo = () => import('../../components/Market/SingleProduct/MPSellerInfo.vue' /* webpackChunkName: "components/market-single-product-m-p-seller-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSellerInfoFull = () => import('../../components/Market/SingleProduct/MPSellerInfoFull.vue' /* webpackChunkName: "components/market-single-product-m-p-seller-info-full" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSellerInfoFullLink = () => import('../../components/Market/SingleProduct/MPSellerInfoFullLink.vue' /* webpackChunkName: "components/market-single-product-m-p-seller-info-full-link" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSellerInfoProductOffer = () => import('../../components/Market/SingleProduct/MPSellerInfoProductOffer.vue' /* webpackChunkName: "components/market-single-product-m-p-seller-info-product-offer" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPShortInfo = () => import('../../components/Market/SingleProduct/MPShortInfo.vue' /* webpackChunkName: "components/market-single-product-m-p-short-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSimilar = () => import('../../components/Market/SingleProduct/MPSimilar.vue' /* webpackChunkName: "components/market-single-product-m-p-similar" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSimilarProducts = () => import('../../components/Market/SingleProduct/MPSimilarProducts.vue' /* webpackChunkName: "components/market-single-product-m-p-similar-products" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPSimilarProductsList = () => import('../../components/Market/SingleProduct/MPSimilarProductsList.vue' /* webpackChunkName: "components/market-single-product-m-p-similar-products-list" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductMPViewedProducts = () => import('../../components/Market/SingleProduct/MPViewedProducts.vue' /* webpackChunkName: "components/market-single-product-m-p-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductNonRefundableProduct = () => import('../../components/Market/SingleProduct/NonRefundableProduct.vue' /* webpackChunkName: "components/market-single-product-non-refundable-product" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductPreorderProductButton = () => import('../../components/Market/SingleProduct/PreorderProductButton.vue' /* webpackChunkName: "components/market-single-product-preorder-product-button" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductSizeChartDialog = () => import('../../components/Market/SingleProduct/ProductSizeChartDialog.vue' /* webpackChunkName: "components/market-single-product-size-chart-dialog" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductRefurbishedDescription = () => import('../../components/Market/SingleProduct/RefurbishedDescription.vue' /* webpackChunkName: "components/market-single-product-refurbished-description" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductServiceInfo = () => import('../../components/Market/SingleProduct/ServiceInfo.vue' /* webpackChunkName: "components/market-single-product-service-info" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductTechDescription = () => import('../../components/Market/SingleProduct/TechDescription.vue' /* webpackChunkName: "components/market-single-product-tech-description" */).then(c => wrapFunctional(c.default || c))
export const OrderOrdersDeliveryChangeOrderDeliveryDate = () => import('../../components/Order/OrdersDelivery/ChangeOrderDeliveryDate.vue' /* webpackChunkName: "components/order-orders-delivery-change-order-delivery-date" */).then(c => wrapFunctional(c.default || c))
export const OrderOrdersDeliveryDate = () => import('../../components/Order/OrdersDelivery/OrdersDeliveryDate.vue' /* webpackChunkName: "components/order-orders-delivery-date" */).then(c => wrapFunctional(c.default || c))
export const OrderOrdersDeliveryTimes = () => import('../../components/Order/OrdersDelivery/OrdersDeliveryTimes.vue' /* webpackChunkName: "components/order-orders-delivery-times" */).then(c => wrapFunctional(c.default || c))
export const OrderTransactionBuyer = () => import('../../components/Order/Transaction/OrderTransactionBuyer.vue' /* webpackChunkName: "components/order-transaction-buyer" */).then(c => wrapFunctional(c.default || c))
export const OrderTransactionInfo = () => import('../../components/Order/Transaction/OrderTransactionInfo.vue' /* webpackChunkName: "components/order-transaction-info" */).then(c => wrapFunctional(c.default || c))
export const OrderTransactionLineItems = () => import('../../components/Order/Transaction/OrderTransactionLineItems.vue' /* webpackChunkName: "components/order-transaction-line-items" */).then(c => wrapFunctional(c.default || c))
export const OrderTransactionSeller = () => import('../../components/Order/Transaction/OrderTransactionSeller.vue' /* webpackChunkName: "components/order-transaction-seller" */).then(c => wrapFunctional(c.default || c))
export const PagesBingoTermsAz = () => import('../../components/Pages/BingoTerms/bingo-terms-az.vue' /* webpackChunkName: "components/pages-bingo-terms-az" */).then(c => wrapFunctional(c.default || c))
export const PagesBingoTermsEn = () => import('../../components/Pages/BingoTerms/bingo-terms-en.vue' /* webpackChunkName: "components/pages-bingo-terms-en" */).then(c => wrapFunctional(c.default || c))
export const PagesBingoTermsRu = () => import('../../components/Pages/BingoTerms/bingo-terms-ru.vue' /* webpackChunkName: "components/pages-bingo-terms-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesBingoTermsDetailsAz = () => import('../../components/Pages/BingoTermsDetails/bingo-terms-details-az.vue' /* webpackChunkName: "components/pages-bingo-terms-details-az" */).then(c => wrapFunctional(c.default || c))
export const PagesBingoTermsDetailsEn = () => import('../../components/Pages/BingoTermsDetails/bingo-terms-details-en.vue' /* webpackChunkName: "components/pages-bingo-terms-details-en" */).then(c => wrapFunctional(c.default || c))
export const PagesBingoTermsDetailsRu = () => import('../../components/Pages/BingoTermsDetails/bingo-terms-details-ru.vue' /* webpackChunkName: "components/pages-bingo-terms-details-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesEKassaFAQEKassaFaqAz = () => import('../../components/Pages/EKassaFAQ/e-kassa-faq-az.vue' /* webpackChunkName: "components/pages-e-kassa-f-a-q-e-kassa-faq-az" */).then(c => wrapFunctional(c.default || c))
export const PagesEKassaFAQEKassaFaqEn = () => import('../../components/Pages/EKassaFAQ/e-kassa-faq-en.vue' /* webpackChunkName: "components/pages-e-kassa-f-a-q-e-kassa-faq-en" */).then(c => wrapFunctional(c.default || c))
export const PagesEKassaFAQEKassaFaqRu = () => import('../../components/Pages/EKassaFAQ/e-kassa-faq-ru.vue' /* webpackChunkName: "components/pages-e-kassa-f-a-q-e-kassa-faq-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToSellSpecialOffer = () => import('../../components/Pages/HowToSell/SpecialOffer.vue' /* webpackChunkName: "components/pages-how-to-sell-special-offer" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToSellAz = () => import('../../components/Pages/HowToSell/how-to-sell-az.vue' /* webpackChunkName: "components/pages-how-to-sell-az" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToSellEn = () => import('../../components/Pages/HowToSell/how-to-sell-en.vue' /* webpackChunkName: "components/pages-how-to-sell-en" */).then(c => wrapFunctional(c.default || c))
export const PagesHowToSellRu = () => import('../../components/Pages/HowToSell/how-to-sell-ru.vue' /* webpackChunkName: "components/pages-how-to-sell-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsBlock = () => import('../../components/Pages/PickUpPoints/Block.vue' /* webpackChunkName: "components/pages-pick-up-points-block" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsZoomSlider = () => import('../../components/Pages/PickUpPoints/PickUpPointsZoomSlider.vue' /* webpackChunkName: "components/pages-pick-up-points-zoom-slider" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPudoMapPopup = () => import('../../components/Pages/PickUpPoints/PickUpPudoMapPopup.vue' /* webpackChunkName: "components/pages-pick-up-pudo-map-popup" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsPudoItems = () => import('../../components/Pages/PickUpPoints/PudoItems.vue' /* webpackChunkName: "components/pages-pick-up-points-pudo-items" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsPudoPointsItem = () => import('../../components/Pages/PickUpPoints/PudoPointsItem.vue' /* webpackChunkName: "components/pages-pick-up-points-pudo-points-item" */).then(c => wrapFunctional(c.default || c))
export const PagesPickUpPointsSearchInputBox = () => import('../../components/Pages/PickUpPoints/SearchInputBox.vue' /* webpackChunkName: "components/pages-pick-up-points-search-input-box" */).then(c => wrapFunctional(c.default || c))
export const PagesProductReviewsRulesAz = () => import('../../components/Pages/ProductReviewsRules/product-reviews-rules-az.vue' /* webpackChunkName: "components/pages-product-reviews-rules-az" */).then(c => wrapFunctional(c.default || c))
export const PagesProductReviewsRulesRu = () => import('../../components/Pages/ProductReviewsRules/product-reviews-rules-ru.vue' /* webpackChunkName: "components/pages-product-reviews-rules-ru" */).then(c => wrapFunctional(c.default || c))
export const PagesSubComponentsQuestionAnswerItem = () => import('../../components/Pages/SubComponents/QuestionAnswerItem.vue' /* webpackChunkName: "components/pages-sub-components-question-answer-item" */).then(c => wrapFunctional(c.default || c))
export const PagesCrossborderFaqAz = () => import('../../components/Pages/crossborderFAQ/crossborder-faq-az.vue' /* webpackChunkName: "components/pages-crossborder-faq-az" */).then(c => wrapFunctional(c.default || c))
export const PagesCrossborderFaqRu = () => import('../../components/Pages/crossborderFAQ/crossborder-faq-ru.vue' /* webpackChunkName: "components/pages-crossborder-faq-ru" */).then(c => wrapFunctional(c.default || c))
export const UIIconsBaseIcon = () => import('../../components/UI/Icons/BaseIcon.vue' /* webpackChunkName: "components/u-i-icons-base-icon" */).then(c => wrapFunctional(c.default || c))
export const UIIconsIconCalendar = () => import('../../components/UI/Icons/IconCalendar.vue' /* webpackChunkName: "components/u-i-icons-icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const UISpinner = () => import('../../components/UI/Spinner/Spinner.vue' /* webpackChunkName: "components/u-i-spinner" */).then(c => wrapFunctional(c.default || c))
export const UIStarRating = () => import('../../components/UI/StarRating/StarRating.vue' /* webpackChunkName: "components/u-i-star-rating" */).then(c => wrapFunctional(c.default || c))
export const UIStar = () => import('../../components/UI/StarRating/star.vue' /* webpackChunkName: "components/u-i-star" */).then(c => wrapFunctional(c.default || c))
export const UIULightbox = () => import('../../components/UI/ULightbox/ULightbox.vue' /* webpackChunkName: "components/u-i-u-lightbox" */).then(c => wrapFunctional(c.default || c))
export const UIUPagination = () => import('../../components/UI/UPagination/UPagination.vue' /* webpackChunkName: "components/u-i-u-pagination" */).then(c => wrapFunctional(c.default || c))
export const UIUPopup = () => import('../../components/UI/UPopup/UPopup.vue' /* webpackChunkName: "components/u-i-u-popup" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersCreditOrdersButtonDownPayment = () => import('../../components/Account/Orders/CreditOrders/ButtonDownPayment.vue' /* webpackChunkName: "components/account-orders-credit-orders-button-down-payment" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersCreditAccountOrder = () => import('../../components/Account/Orders/CreditOrders/CreditAccountOrder.vue' /* webpackChunkName: "components/account-orders-credit-account-order" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersCreditListOrders = () => import('../../components/Account/Orders/CreditOrders/CreditListOrders.vue' /* webpackChunkName: "components/account-orders-credit-list-orders" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersCreditOrdersOrderStatus = () => import('../../components/Account/Orders/CreditOrders/OrderStatus.vue' /* webpackChunkName: "components/account-orders-credit-orders-order-status" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderStatus = () => import('../../components/Account/Orders/Order/OrderStatus.vue' /* webpackChunkName: "components/account-orders-order-status" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderSingleOrderPageCredit = () => import('../../components/Account/Orders/Order/SingleOrderPageCredit.vue' /* webpackChunkName: "components/account-orders-order-single-order-page-credit" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesCategorySelectBody = () => import('../../components/Catalog/Categories/CategorySelect/Body.vue' /* webpackChunkName: "components/catalog-categories-category-select-body" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesCategorySelectCChild = () => import('../../components/Catalog/Categories/CategorySelect/CChild.vue' /* webpackChunkName: "components/catalog-categories-category-select-c-child" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesCategorySelect = () => import('../../components/Catalog/Categories/CategorySelect/CategorySelect.vue' /* webpackChunkName: "components/catalog-categories-category-select" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesCategorySelectHeader = () => import('../../components/Catalog/Categories/CategorySelect/Header.vue' /* webpackChunkName: "components/catalog-categories-category-select-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesFiltersClearFilters = () => import('../../components/Catalog/Categories/Filters/ClearFilters.vue' /* webpackChunkName: "components/catalog-categories-filters-clear-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesFilters = () => import('../../components/Catalog/Categories/Filters/Filters.vue' /* webpackChunkName: "components/catalog-categories-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesFiltersHeader = () => import('../../components/Catalog/Categories/Filters/FiltersHeader.vue' /* webpackChunkName: "components/catalog-categories-filters-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesFiltersPartnerFilters = () => import('../../components/Catalog/Categories/Filters/PartnerFilters.vue' /* webpackChunkName: "components/catalog-categories-filters-partner-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesFiltersPointFilters = () => import('../../components/Catalog/Categories/Filters/PointFilters.vue' /* webpackChunkName: "components/catalog-categories-filters-point-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesRootCategories = () => import('../../components/Catalog/Categories/RootCategories/RootCategories.vue' /* webpackChunkName: "components/catalog-categories-root-categories" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesRootHeader = () => import('../../components/Catalog/Categories/RootCategories/RootHeader.vue' /* webpackChunkName: "components/catalog-categories-root-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesRootSortBy = () => import('../../components/Catalog/Categories/RootCategories/RootSortBy.vue' /* webpackChunkName: "components/catalog-categories-root-sort-by" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoriesSeoText = () => import('../../components/Catalog/Categories/Seo/SeoText.vue' /* webpackChunkName: "components/catalog-categories-seo-text" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditCreditLoanApplicationFormsFirstLoanForm = () => import('../../components/Checkout/Credit/CreditLoanApplicationForms/FirstLoanForm.vue' /* webpackChunkName: "components/checkout-credit-credit-loan-application-forms-first-loan-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditCreditLoanApplicationForm = () => import('../../components/Checkout/Credit/CreditLoanApplicationForms/LoanApplicationForm.vue' /* webpackChunkName: "components/checkout-credit-credit-loan-application-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditCreditLoanApplicationFormsPhoneFriends = () => import('../../components/Checkout/Credit/CreditLoanApplicationForms/PhoneFriends.vue' /* webpackChunkName: "components/checkout-credit-credit-loan-application-forms-phone-friends" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditCreditLoanApplicationFormsRepeatedLoanForm = () => import('../../components/Checkout/Credit/CreditLoanApplicationForms/RepeatedLoanForm.vue' /* webpackChunkName: "components/checkout-credit-credit-loan-application-forms-repeated-loan-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalTimerTextFirstCredit = () => import('../../components/Checkout/Credit/ModalTimerText/FirstCredit.vue' /* webpackChunkName: "components/checkout-credit-modal-timer-text-first-credit" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalTimerTextLineWithoutCart = () => import('../../components/Checkout/Credit/ModalTimerText/LineWithoutCart.vue' /* webpackChunkName: "components/checkout-credit-modal-timer-text-line-without-cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalTimerTextSecondCredit = () => import('../../components/Checkout/Credit/ModalTimerText/SecondCredit.vue' /* webpackChunkName: "components/checkout-credit-modal-timer-text-second-credit" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalCartLoanMoreTime = () => import('../../components/Checkout/Credit/ModalsBankLoan/CheckoutModalCartLoanMoreTime.vue' /* webpackChunkName: "components/checkout-modal-cart-loan-more-time" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalsBankLoanModalBankOffers = () => import('../../components/Checkout/Credit/ModalsBankLoan/ModalBankOffers.vue' /* webpackChunkName: "components/checkout-credit-modals-bank-loan-modal-bank-offers" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalsBankLoanModalCreditLimit = () => import('../../components/Checkout/Credit/ModalsBankLoan/ModalCreditLimit.vue' /* webpackChunkName: "components/checkout-credit-modals-bank-loan-modal-credit-limit" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalsBankLoanModalDownPaymentApproved = () => import('../../components/Checkout/Credit/ModalsBankLoan/ModalDownPaymentApproved.vue' /* webpackChunkName: "components/checkout-credit-modals-bank-loan-modal-down-payment-approved" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalsBankLoanSliderInstallmentMonths = () => import('../../components/Checkout/Credit/ModalsBankLoan/SliderInstallmentMonths.vue' /* webpackChunkName: "components/checkout-credit-modals-bank-loan-slider-installment-months" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditModalsBankLoanWaitingConfirmBank = () => import('../../components/Checkout/Credit/ModalsBankLoan/WaitingConfirmBank.vue' /* webpackChunkName: "components/checkout-credit-modals-bank-loan-waiting-confirm-bank" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationStatusCart = () => import('../../components/Checkout/Credit/ThankYouPageComponents/CreditVerificationStatusCart.vue' /* webpackChunkName: "components/checkout-credit-verification-status-cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationStatusLine = () => import('../../components/Checkout/Credit/ThankYouPageComponents/CreditVerificationStatusLine.vue' /* webpackChunkName: "components/checkout-credit-verification-status-line" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditThankYouPageComponentsStatusLinAprove = () => import('../../components/Checkout/Credit/ThankYouPageComponents/StatusLinAprove.vue' /* webpackChunkName: "components/checkout-credit-thank-you-page-components-status-lin-aprove" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationAproved = () => import('../../components/Checkout/Credit/Verification/Aproved.vue' /* webpackChunkName: "components/checkout-credit-verification-aproved" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationAprovedSecond = () => import('../../components/Checkout/Credit/Verification/AprovedSecond.vue' /* webpackChunkName: "components/checkout-credit-verification-aproved-second" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationDownpaymantAproved = () => import('../../components/Checkout/Credit/Verification/DownpaymantAproved.vue' /* webpackChunkName: "components/checkout-credit-verification-downpaymant-aproved" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationDownpaymantAprovedSecond = () => import('../../components/Checkout/Credit/Verification/DownpaymantAprovedSecond.vue' /* webpackChunkName: "components/checkout-credit-verification-downpaymant-aproved-second" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationPartialAproved = () => import('../../components/Checkout/Credit/Verification/PartialAproved.vue' /* webpackChunkName: "components/checkout-credit-verification-partial-aproved" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationPending = () => import('../../components/Checkout/Credit/Verification/Pending.vue' /* webpackChunkName: "components/checkout-credit-verification-pending" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationPendingSecond = () => import('../../components/Checkout/Credit/Verification/PendingSecond.vue' /* webpackChunkName: "components/checkout-credit-verification-pending-second" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCreditVerificationRejected = () => import('../../components/Checkout/Credit/Verification/Rejected.vue' /* webpackChunkName: "components/checkout-credit-verification-rejected" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutAddressMapYandex = () => import('../../components/Checkout/NewCheckout/Address/MapYandex.vue' /* webpackChunkName: "components/checkout-new-checkout-address-map-yandex" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutAddressSetAddress = () => import('../../components/Checkout/NewCheckout/Address/SetAddress.vue' /* webpackChunkName: "components/checkout-new-checkout-address-set-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutAddressSetInput = () => import('../../components/Checkout/NewCheckout/Address/SetInput.vue' /* webpackChunkName: "components/checkout-new-checkout-address-set-input" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditDetails = () => import('../../components/Checkout/NewCheckout/Credit/Details.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-details" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditMinSumForLoanPopup = () => import('../../components/Checkout/NewCheckout/Credit/MinSumForLoanPopup.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-min-sum-for-loan-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditNotAvailablePopup = () => import('../../components/Checkout/NewCheckout/Credit/NotAvailablePopup.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-not-available-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditPaymentInfo = () => import('../../components/Checkout/NewCheckout/Credit/PaymentInfo.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-payment-info" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryBox = () => import('../../components/Checkout/NewCheckout/Delivery/Box.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryCourierStandard = () => import('../../components/Checkout/NewCheckout/Delivery/CourierStandard.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-courier-standard" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryExpress = () => import('../../components/Checkout/NewCheckout/Delivery/Express.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-express" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryPrefilledAddress = () => import('../../components/Checkout/NewCheckout/Delivery/PrefilledAddress.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-prefilled-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryPudo = () => import('../../components/Checkout/NewCheckout/Delivery/Pudo.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-pudo" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryPudoAzerpost = () => import('../../components/Checkout/NewCheckout/Delivery/PudoAzerpost.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-pudo-azerpost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutDeliveryUserAddressList = () => import('../../components/Checkout/NewCheckout/Delivery/UserAddressList.vue' /* webpackChunkName: "components/checkout-new-checkout-delivery-user-address-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutOrderItem = () => import('../../components/Checkout/NewCheckout/Order/Item.vue' /* webpackChunkName: "components/checkout-new-checkout-order-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentBlock = () => import('../../components/Checkout/NewCheckout/Payment/Block.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-block" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentBox = () => import('../../components/Checkout/NewCheckout/Payment/Box.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentCardsList = () => import('../../components/Checkout/NewCheckout/Payment/CardsList.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-cards-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentRecipientDetails = () => import('../../components/Checkout/NewCheckout/Payment/RecipientDetails.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-recipient-details" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentSubmitButton = () => import('../../components/Checkout/NewCheckout/Payment/SubmitButton.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-submit-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPriceBox = () => import('../../components/Checkout/NewCheckout/Price/Box.vue' /* webpackChunkName: "components/checkout-new-checkout-price-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPricePremiumSummary = () => import('../../components/Checkout/NewCheckout/Price/PremiumSummary.vue' /* webpackChunkName: "components/checkout-new-checkout-price-premium-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPromocodeBox = () => import('../../components/Checkout/NewCheckout/Promocode/Box.vue' /* webpackChunkName: "components/checkout-new-checkout-promocode-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPromocodeInfoBlock = () => import('../../components/Checkout/NewCheckout/Promocode/InfoBlock.vue' /* webpackChunkName: "components/checkout-new-checkout-promocode-info-block" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPromocodeNotAllowedCombinationPopup = () => import('../../components/Checkout/NewCheckout/Promocode/NotAllowedCombinationPopup.vue' /* webpackChunkName: "components/checkout-new-checkout-promocode-not-allowed-combination-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPromocodePremiumSwitcher = () => import('../../components/Checkout/NewCheckout/Promocode/PremiumSwitcher.vue' /* webpackChunkName: "components/checkout-new-checkout-promocode-premium-switcher" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPromocodeCheckbox = () => import('../../components/Checkout/NewCheckout/Promocode/PromocodeCheckbox.vue' /* webpackChunkName: "components/checkout-new-checkout-promocode-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPudoImageSlider = () => import('../../components/Checkout/NewCheckout/Pudo/ImageSlider.vue' /* webpackChunkName: "components/checkout-new-checkout-pudo-image-slider" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPudoMapSelect = () => import('../../components/Checkout/NewCheckout/Pudo/MapSelect.vue' /* webpackChunkName: "components/checkout-new-checkout-pudo-map-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPudoMapSelectAzerpost = () => import('../../components/Checkout/NewCheckout/Pudo/MapSelectAzerpost.vue' /* webpackChunkName: "components/checkout-new-checkout-pudo-map-select-azerpost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPudoPointsMap = () => import('../../components/Checkout/NewCheckout/Pudo/PointsMap.vue' /* webpackChunkName: "components/checkout-new-checkout-pudo-points-map" */).then(c => wrapFunctional(c.default || c))
export const MarketCartPopupsCreditBuyOnCreditPopupWarning = () => import('../../components/Market/Cart/PopupsCredit/BuyOnCreditPopupWarning.vue' /* webpackChunkName: "components/market-cart-popups-credit-buy-on-credit-popup-warning" */).then(c => wrapFunctional(c.default || c))
export const MarketCartPopupsCreditUnavailable = () => import('../../components/Market/Cart/PopupsCredit/CreditUnavailable.vue' /* webpackChunkName: "components/market-cart-popups-credit-unavailable" */).then(c => wrapFunctional(c.default || c))
export const MarketCartPopupsCreditNotAvailableWarning = () => import('../../components/Market/Cart/PopupsCredit/NotAvailableWarning.vue' /* webpackChunkName: "components/market-cart-popups-credit-not-available-warning" */).then(c => wrapFunctional(c.default || c))
export const MarketCreditLineNotAvailableModal = () => import('../../components/Market/Credit/Popups/CreditLineNotAvailableModal.vue' /* webpackChunkName: "components/market-credit-line-not-available-modal" */).then(c => wrapFunctional(c.default || c))
export const MarketCreditPopupsInSufficientCreditPopup = () => import('../../components/Market/Credit/Popups/InSufficientCreditPopup.vue' /* webpackChunkName: "components/market-credit-popups-in-sufficient-credit-popup" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersSliderRangeButton = () => import('../../components/Market/Filters/SliderRange/button.vue' /* webpackChunkName: "components/market-filters-slider-range-button" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersSliderRangeInputNumber = () => import('../../components/Market/Filters/SliderRange/input-number.vue' /* webpackChunkName: "components/market-filters-slider-range-input-number" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersSliderRangeMain = () => import('../../components/Market/Filters/SliderRange/main.vue' /* webpackChunkName: "components/market-filters-slider-range-main" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductVariantsTypesAvailableVariants = () => import('../../components/Market/SingleProduct/VariantsTypes/AvailableVariants.vue' /* webpackChunkName: "components/market-single-product-variants-types-available-variants" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductVariantsTypesMPTypesVariants = () => import('../../components/Market/SingleProduct/VariantsTypes/MPTypesVariants.vue' /* webpackChunkName: "components/market-single-product-variants-types-m-p-types-variants" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductVariantsButtons = () => import('../../components/Market/SingleProduct/VariantsTypes/VariantsButtons.vue' /* webpackChunkName: "components/market-single-product-variants-buttons" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductVariantsImg = () => import('../../components/Market/SingleProduct/VariantsTypes/VariantsImg.vue' /* webpackChunkName: "components/market-single-product-variants-img" */).then(c => wrapFunctional(c.default || c))
export const MarketSingleProductVariantsSelect = () => import('../../components/Market/SingleProduct/VariantsTypes/VariantsSelect.vue' /* webpackChunkName: "components/market-single-product-variants-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditLoanApplicationForm = () => import('../../components/Checkout/NewCheckout/Credit/LoanApplication/Form.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-loan-application-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditLoanApplicationIncorrectFinCodePopup = () => import('../../components/Checkout/NewCheckout/Credit/LoanApplication/IncorrectFinCodePopup.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-loan-application-incorrect-fin-code-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditLoanApplicationPhoneFriend = () => import('../../components/Checkout/NewCheckout/Credit/LoanApplication/PhoneFriend.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-loan-application-phone-friend" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutCreditLoanApplicationSelectTerm = () => import('../../components/Checkout/NewCheckout/Credit/LoanApplication/SelectTerm.vue' /* webpackChunkName: "components/checkout-new-checkout-credit-loan-application-select-term" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentMethodCardEcomKb = () => import('../../components/Checkout/NewCheckout/Payment/Method/CardEcomKb.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-method-card-ecom-kb" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentMethodCardEcomKbInstallment = () => import('../../components/Checkout/NewCheckout/Payment/Method/CardEcomKbInstallment.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-method-card-ecom-kb-installment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentMethodCardOnDelivery = () => import('../../components/Checkout/NewCheckout/Payment/Method/CardOnDelivery.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-method-card-on-delivery" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentMethodCashOnDelivery = () => import('../../components/Checkout/NewCheckout/Payment/Method/CashOnDelivery.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-method-cash-on-delivery" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewCheckoutPaymentMethodLoanOnDelivery = () => import('../../components/Checkout/NewCheckout/Payment/Method/LoanOnDelivery.vue' /* webpackChunkName: "components/checkout-new-checkout-payment-method-loan-on-delivery" */).then(c => wrapFunctional(c.default || c))
export const MarketFiltersSliderRangeInput = () => import('../../components/Market/Filters/SliderRange/input/input.vue' /* webpackChunkName: "components/market-filters-slider-range-input" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
