
import { mapActions, mapState, storeToRefs } from 'pinia'
import startObserverLoading from '@/mixins/startObserverLoading.js'
import { INCLUDE_FIELDS } from '@/constants'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { useCustomer } from '@/stores/customer'

export default {
  name: 'MPLandingRecommended',
  fetchOnServer: true,
  isLoadingComponent: false,
  mixins: [startObserverLoading],
  props: {
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      customerStore,
      uiStore,
      resolution,
    }
  },
  data() {
    return {
      isRecommendedProductsVisible: false,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.getProducts()
      if (this.marketStore.products.length) {
        this.isRecommendedProductsVisible = true
      }
    }
  },
  computed: {
    ...mapState(useMarketStore, ['products', 'pagination']),
    limit() {
      let limit = 15
      if (this.resolution === 'lg') {
        limit = 12
      }
      if (this.resolution === 'md') {
        limit = 9
      }
      if (this.resolution === 'sm') {
        limit = 6
      }
      return limit
    },
  },
  mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.MPLandingRecommended,
        this.showRecommendedProducts,
      )
    }
  },
  methods: {
    async showRecommendedProducts() {
      await this.getProducts()
      if (this.marketStore.products.length) {
        this.isRecommendedProductsVisible = true
      }
    },
    async getProducts() {
      if (this.isLoadingComponent) return
      try {
        this.isLoadingComponent = true
        await this.marketStore.mainPageBestsellers()
      } catch (e) {
        console.log('ERROR:MPLandingRecommended: mainPageBestsellers', e)
      }
    },
  },
}
