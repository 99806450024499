
import { useMarketStore } from '~/stores/market'
import { INCLUDE_FIELDS } from '@/constants'
import { useCustomer } from '@/stores/customer'
import { ref, useContext } from '@nuxtjs/composition-api'
import startObserverLoading from '@/mixins/startObserverLoading'

export default {
  name: 'MPPersonalRecommendations',
  mixins: [startObserverLoading],

  props: {
    isProduct: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const { $device } = useContext()
    const totalSlidesCount = ref(15)
    let slidePerPage = 0
    let keenSliderOptions = {}
    if ($device && $device.isMobile && !$device.isCrawler) {
      slidePerPage = 2
      keenSliderOptions = {
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    } else if ($device && $device.isTablet && !$device.isCrawler) {
      slidePerPage = 3
      keenSliderOptions = {
        rubberband: false,
        breakpoints: {
          '(max-width: 768px)': {
            slides: { perView: 2, spacing: 8 }, // Up to 768px
          },
          '(min-width: 769px) and (max-width: 1000px)': {
            slides: { perView: slidePerPage, spacing: 8 }, // From 769px to 1000px
          },
          '(min-width: 1001px)': {
            slides: { perView: slidePerPage + 1, spacing: 8 }, // Greater than 1000px
          },
        },
      }
    } else if ($device && $device.isDesktop && !$device.isCrawler) {
      slidePerPage = 5

      keenSliderOptions = {
        breakpoints: {
          '(orientation: portrait)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(orientation: portrait) and (min-width: 1024px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape) and (min-width: 1200px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    }
    return {
      marketStore,
      keenSliderOptions,
      totalSlidesCount,
      slidePerPage,
      customerStore,
    }
  },
  data() {
    return {
      isLoading: false,
      waitingToLoad: true,
    }
  },

  computed: {
    hasProducts() {
      return !!this.marketStore.personalRecommendations.length
    },
  },
  mounted() {
    this.startObserverLoading(
      this.$refs.MPPersonalRecommendationsRef,
      this.startLoadingComponentPersonalRecommendations,
    )
  },
  methods: {
    async startLoadingComponentPersonalRecommendations() {
      if (this.loadOnce) return
      if (
        this.customerStore.isLoggedIn &&
        this.$auth.$storage.getCookie('user_ext_id')
      ) {
        try {
          this.isLoading = true
          await this.marketStore.getPersonalRecommendationsProducts({
            page: 1,
            per_page: 10,
          })
        } catch (e) {
          console.log('Error: getPersonalRecommendationsProducts, ', e)
        } finally {
          this.isLoading = false
          this.loadOnce = true
          this.waitingToLoad = false
        }
      }
    },
  },
}
