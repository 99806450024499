
import startObserverLoading from '@/mixins/startObserverLoading'
import { useMarketStore } from '@/stores/market'
import { storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/ui'
import { useCustomer } from '@/stores/customer'
export default {
  name: 'MPViewedProducts',
  fetchOnServer: false,
  mixins: [startObserverLoading],
  props: {
    isProduct: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const customerStore = useCustomer()
    const { resolution } = storeToRefs(uiStore)
    return { marketStore, uiStore, resolution, customerStore }
  },
  data() {
    return {
      products: [],
      loadOnce: false,
      waitingToLoad: true,
    }
  },
  computed: {
    isLoggedIn() {
      return this.customerStore.isLoggedIn
    },
    limit() {
      let limit = 5
      if (this.resolution === 'lg') {
        limit = 4
      }
      if (this.resolution === 'md') {
        limit = 3
      }
      if (this.resolution === 'sm') {
        limit = 4
      }
      return limit
    },
  },
  watch: {
    isLoggedIn(val, oldval) {
      if (!val && oldval) {
        this.loadOnce = false
        this.startLoadingComponent()
      }
    },
  },
  mounted() {
    this.startObserverLoading(
      this.$refs?.MPViewedRef,
      this.startLoadingComponent,
    )
  },
  methods: {
    async startLoadingComponent() {
      if (this.loadOnce) return
      if (
        this.customerStore.isLoggedIn &&
        this.$auth.$storage.getCookie('user_ext_id')
      ) {
        this.products = await this.marketStore.getViewedProductsUpdated()
      } else {
        const customer_id = this.$auth.$storage.getLocalStorage('customer_id')
        if (customer_id) {
          this.products = await this.marketStore.getViewedProductsUpdated({
            session_id: customer_id,
          })
        }
      }
      this.waitingToLoad = false
      this.loadOnce = true
    },
  },
}
