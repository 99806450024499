import { defineStore } from 'pinia'

export const useLotteryStore = defineStore('lottery', {
  getters: {},
  state: () => ({
    isLottery: null,
    dataPopup: {
      details: [],
    },
    isSubscribe: false,
  }),
  actions: {
    async GET_LOTTERY() {
      try {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_CART_API_URL ||
            this.$nuxt.$config.CART_API_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
        }
        const {
          data: { data },
        } = await this.$nuxt.$axios.get(
          `/default-settings/UMICO_MARKET_LOTTERY`,
        )
        this.isLottery = data?.value?.bool
        return data?.value?.bool
      } catch {
        console.log('ERROR LOTTERY')
        this.isLottery = false
        return false
      }
    },
    async GET_LOTTERY_POPUP_DATE() {
      this.isSubscribe = false
      try {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.LOTTERY_URL + '/api/v1'

        const {
          data: { data },
        } = await this.$nuxt.$axios.get(`/onboardings`)
        this.dataPopup = data

        return data?.value?.bool
      } catch {
        console.log('ERROR POPUP LOTTERY')
        return false
      }
    },
    async SUBSCRIBE_LOTTERY(payload) {
      try {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.LOTTERY_URL + '/api/v1'
        await this.$nuxt.$axios.put(`/lotteries/status`, payload)
        this.isSubscribe = true
      } catch {
        console.log('ERROR POPUP LOTTERY')
        return false
      }
    },
  },
})
