
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useLotteryStore } from '~/stores/lottery'
export default {
  name: 'MPLandingIconBadges',
  setup() {
    const LotteryStore = useLotteryStore()
    return {
      LotteryStore,
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, [
      'bir_bank_installment',
      'free_delivered_main',
    ]),
    ...mapWritableState(useLotteryStore, ['isLottery']),
    freeDelivery() {
      return this.free_delivered_main?.text
    },
    isFreeDelivery() {
      return this.free_delivered_main && !this.free_delivered_main?.is_hidden
    },
    isBirbankInstallment() {
      return this.bir_bank_installment && !this.bir_bank_installment?.is_hidden
    },
    birbank_installment_image() {
      return this.bir_bank_installment
        ? this.bir_bank_installment?.images?.['1']
        : null
    },
    birbank_installment_text() {
      return this.bir_bank_installment ? this.bir_bank_installment?.text : null
    },
    linkLottery() {
      return (
        this.$config.LOTTERY_LANDING +
        (this.$i18n.locale === 'ru' ? 'index_ru.html' : '')
      )
    },
  },
}
