!(function (e) {
  var t
  'object' == typeof exports && 'undefined' != typeof module
    ? (module.exports = e())
    : 'function' == typeof define && define.amd
    ? define([], e)
    : ('undefined' != typeof window
        ? (t = window)
        : 'undefined' != typeof global
        ? (t = global)
        : 'undefined' != typeof self && (t = self),
      (t.sbjs = e()))
})(function () {
  return (function n(s, a, i) {
    function o(r, e) {
      if (!a[r]) {
        if (!s[r]) {
          var t = 'function' == typeof require && require
          if (!e && t) return t(r, !0)
          if (c) return c(r, !0)
          e = new Error("Cannot find module '" + r + "'")
          throw ((e.code = 'MODULE_NOT_FOUND'), e)
        }
        t = a[r] = { exports: {} }
        s[r][0].call(
          t.exports,
          function (e) {
            var t = s[r][1][e]
            return o(t || e)
          },
          t,
          t.exports,
          n,
          s,
          a,
          i,
        )
      }
      return a[r].exports
    }
    for (
      var c = 'function' == typeof require && require, e = 0;
      e < i.length;
      e++
    )
      o(i[e])
    return o
  })(
    {
      1: [
        function (e, t, r) {
          'use strict'
          var n = e('./init')
          t.exports = {
            init: function (e) {
              ;(this.get = n(e)),
                e &&
                  e.callback &&
                  'function' == typeof e.callback &&
                  e.callback(this.get)
            },
          }
        },
        { './init': 6 },
      ],
      2: [
        function (e, t, r) {
          'use strict'
          var n = e('./terms'),
            s = e('./helpers/utils'),
            a = {
              containers: {
                current: 'sbjs_current',
                current_extra: 'sbjs_current_add',
                first: 'sbjs_first',
                first_extra: 'sbjs_first_add',
                session: 'sbjs_session',
                udata: 'sbjs_udata',
                promocode: 'sbjs_promo',
              },
              service: { migrations: 'sbjs_migrations' },
              delimiter: '|||',
              aliases: {
                main: {
                  type: 'typ',
                  source: 'src',
                  medium: 'mdm',
                  campaign: 'cmp',
                  content: 'cnt',
                  term: 'trm',
                },
                extra: { fire_date: 'fd', entrance_point: 'ep', referer: 'rf' },
                session: { pages_seen: 'pgs', current_page: 'cpg' },
                udata: { visits: 'vst', ip: 'uip', agent: 'uag' },
                promo: 'code',
              },
              pack: {
                main: function (e) {
                  return (
                    a.aliases.main.type +
                    '=' +
                    e.type +
                    a.delimiter +
                    a.aliases.main.source +
                    '=' +
                    e.source +
                    a.delimiter +
                    a.aliases.main.medium +
                    '=' +
                    e.medium +
                    a.delimiter +
                    a.aliases.main.campaign +
                    '=' +
                    e.campaign +
                    a.delimiter +
                    a.aliases.main.content +
                    '=' +
                    e.content +
                    a.delimiter +
                    a.aliases.main.term +
                    '=' +
                    e.term
                  )
                },
                extra: function (e) {
                  return (
                    a.aliases.extra.fire_date +
                    '=' +
                    s.setDate(new Date(), e) +
                    a.delimiter +
                    a.aliases.extra.entrance_point +
                    '=' +
                    document.location.href +
                    a.delimiter +
                    a.aliases.extra.referer +
                    '=' +
                    (document.referrer || n.none)
                  )
                },
                user: function (e, t) {
                  return (
                    a.aliases.udata.visits +
                    '=' +
                    e +
                    a.delimiter +
                    a.aliases.udata.ip +
                    '=' +
                    t +
                    a.delimiter +
                    a.aliases.udata.agent +
                    '=' +
                    navigator.userAgent
                  )
                },
                session: function (e) {
                  return (
                    a.aliases.session.pages_seen +
                    '=' +
                    e +
                    a.delimiter +
                    a.aliases.session.current_page +
                    '=' +
                    document.location.href
                  )
                },
                promo: function (e) {
                  return (
                    a.aliases.promo +
                    '=' +
                    s.setLeadingZeroToInt(
                      s.randomInt(e.min, e.max),
                      e.max.toString().length,
                    )
                  )
                },
              },
            }
          t.exports = a
        },
        { './helpers/utils': 5, './terms': 9 },
      ],
      3: [
        function (e, t, r) {
          'use strict'
          var m = e('../data').delimiter
          t.exports = {
            encodeData: function (e) {
              return encodeURIComponent(e)
                .replace(/\!/g, '%21')
                .replace(/\~/g, '%7E')
                .replace(/\*/g, '%2A')
                .replace(/\'/g, '%27')
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')
            },
            decodeData: function (t) {
              try {
                return decodeURIComponent(t)
                  .replace(/\%21/g, '!')
                  .replace(/\%7E/g, '~')
                  .replace(/\%2A/g, '*')
                  .replace(/\%27/g, "'")
                  .replace(/\%28/g, '(')
                  .replace(/\%29/g, ')')
              } catch (e) {
                try {
                  return unescape(t)
                } catch (e) {
                  return ''
                }
              }
            },
            set: function (e, t, r, n, s) {
              var a,
                i = null,
                r =
                  (r &&
                    ((a = new Date()).setTime(a.getTime() + 60 * r * 1e3),
                    (i = a.getTime())),
                  { value: this.encodeData(t), expiresAt: i })
              localStorage.setItem(this.encodeData(e), JSON.stringify(r))
            },
            get: function (e) {
              var t = localStorage.getItem(this.encodeData(e))
              if (!t) return null
              try {
                var r = JSON.parse(t)
                return r.expiresAt && Date.now() > r.expiresAt
                  ? (localStorage.removeItem(this.encodeData(e)), null)
                  : this.decodeData(r.value)
              } catch (e) {
                return null
              }
            },
            destroy: function (e) {
              localStorage.removeItem(this.encodeData(e))
            },
            parse: function (e) {
              var t = [],
                r = {}
              if ('string' == typeof e) t.push(e)
              else for (var n in e) e.hasOwnProperty(n) && t.push(e[n])
              for (var s = 0; s < t.length; s++)
                for (
                  var a = this.get(t[s]),
                    i = this.unsbjs(t[s]),
                    o = ((r[i] = {}), a ? a.split(m) : []),
                    c = 0;
                  c < o.length;
                  c++
                ) {
                  var u = o[c].split('='),
                    p = u.splice(0, 1)
                  p.push(u.join('=')), (r[i][p[0]] = this.decodeData(p[1]))
                }
              return r
            },
            unsbjs: function (e) {
              return e.replace('sbjs_', '')
            },
          }
        },
        { '../data': 2 },
      ],
      4: [
        function (e, t, r) {
          'use strict'
          t.exports = {
            parse: function (e) {
              for (
                var n = this.parseOptions,
                  t = n.parser[n.strictMode ? 'strict' : 'loose'].exec(e),
                  s = {},
                  r = 14;
                r--;

              )
                s[n.key[r]] = t[r] || ''
              return (
                (s[n.q.name] = {}),
                s[n.key[12]].replace(n.q.parser, function (e, t, r) {
                  t && (s[n.q.name][t] = r)
                }),
                s
              )
            },
            parseOptions: {
              strictMode: !1,
              key: [
                'source',
                'protocol',
                'authority',
                'userInfo',
                'user',
                'password',
                'host',
                'port',
                'relative',
                'path',
                'directory',
                'file',
                'query',
                'anchor',
              ],
              q: { name: 'queryKey', parser: /(?:^|&)([^&=]*)=?([^&]*)/g },
              parser: {
                strict:
                  /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
                loose:
                  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
              },
            },
            getParam: function (e) {
              for (
                var t = {},
                  r = (e || window.location.search.substring(1)).split('&'),
                  n = 0;
                n < r.length;
                n++
              ) {
                var s = r[n].split('=')
                void 0 === t[s[0]]
                  ? (t[s[0]] = s[1])
                  : 'string' == typeof t[s[0]]
                  ? (t[s[0]] = [t[s[0]], s[1]])
                  : t[s[0]].push(s[1])
              }
              return t
            },
            getHost: function (e) {
              return this.parse(e).host.replace('www.', '')
            },
          }
        },
        {},
      ],
      5: [
        function (e, t, r) {
          'use strict'
          t.exports = {
            escapeRegexp: function (e) {
              return e.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
            },
            setDate: function (e, t) {
              var r = e.getTimezoneOffset() / 60,
                n = e.getHours()
              return (
                e.setHours(n + r + (t || 0 === t ? t : -r)),
                e.getFullYear() +
                  '-' +
                  this.setLeadingZeroToInt(e.getMonth() + 1, 2) +
                  '-' +
                  this.setLeadingZeroToInt(e.getDate(), 2) +
                  ' ' +
                  this.setLeadingZeroToInt(e.getHours(), 2) +
                  ':' +
                  this.setLeadingZeroToInt(e.getMinutes(), 2) +
                  ':' +
                  this.setLeadingZeroToInt(e.getSeconds(), 2)
              )
            },
            setLeadingZeroToInt: function (e, t) {
              for (var r = e + ''; r.length < t; ) r = '0' + r
              return r
            },
            randomInt: function (e, t) {
              return Math.floor(Math.random() * (t - e + 1)) + e
            },
          }
        },
        {},
      ],
      6: [
        function (e, t, r) {
          'use strict'
          var q = e('./data'),
            D = e('./terms'),
            R = e('./helpers/cookies'),
            H = e('./helpers/uri'),
            S = e('./helpers/utils'),
            E = e('./params'),
            T = e('./migrations')
          t.exports = function (e) {
            var t,
              a,
              r,
              n,
              s,
              i,
              o = E.fetch(e),
              c = H.getParam(),
              u = o.domain.host,
              p = o.domain.isolate,
              m = o.lifetime
            function l(e) {
              switch (e) {
                case D.traffic.utm:
                  ;(t = D.traffic.utm),
                    (a =
                      void 0 !== c.utm_source
                        ? c.utm_source
                        : void 0 !== c.gclid
                        ? 'google'
                        : void 0 !== c.yclid
                        ? 'yandex'
                        : D.none),
                    (r =
                      void 0 !== c.utm_medium
                        ? c.utm_medium
                        : void 0 !== c.gclid || void 0 !== c.yclid
                        ? 'cpc'
                        : D.none),
                    (n =
                      void 0 !== c.utm_campaign
                        ? c.utm_campaign
                        : void 0 !== c[o.campaign_param]
                        ? c[o.campaign_param]
                        : void 0 !== c.gclid
                        ? 'google_cpc'
                        : void 0 !== c.yclid
                        ? 'yandex_cpc'
                        : D.none),
                    (s =
                      void 0 !== c.utm_content
                        ? c.utm_content
                        : void 0 !== c[o.content_param]
                        ? c[o.content_param]
                        : D.none),
                    (i =
                      void 0 !== c.utm_term
                        ? c.utm_term
                        : void 0 !== c[o.term_param]
                        ? c[o.term_param]
                        : (function () {
                            var e = document.referrer
                            {
                              if (c.utm_term) return c.utm_term
                              if (
                                !(
                                  e &&
                                  H.parse(e).host &&
                                  H.parse(e).host.match(
                                    /^(?:.*\.)?yandex\..{2,9}$/i,
                                  )
                                )
                              )
                                return !1
                              try {
                                return H.getParam(
                                  H.parse(document.referrer).query,
                                ).text
                              } catch (e) {
                                return !1
                              }
                            }
                          })() || D.none)
                  break
                case D.traffic.organic:
                  ;(t = D.traffic.organic),
                    (a = a || H.getHost(document.referrer)),
                    (r = D.referer.organic),
                    (n = D.none),
                    (s = D.none),
                    (i = D.none)
                  break
                case D.traffic.referral:
                  ;(t = D.traffic.referral),
                    (a = a || H.getHost(document.referrer)),
                    (r = r || D.referer.referral),
                    (n = D.none),
                    (s = H.parse(document.referrer).path),
                    (i = D.none)
                  break
                case D.traffic.typein:
                  ;(t = D.traffic.typein),
                    (a = o.typein_attributes.source),
                    (r = o.typein_attributes.medium),
                    (n = D.none),
                    (s = D.none),
                    (i = D.none)
                  break
                default:
                  ;(t = D.oops),
                    (a = D.oops),
                    (r = D.oops),
                    (n = D.oops),
                    (s = D.oops),
                    (i = D.oops)
              }
              e = {
                type: t,
                source: a,
                medium: r,
                campaign: n,
                content: s,
                term: i,
              }
              return q.pack.main(e)
            }
            function f(e) {
              var t = document.referrer
              switch (e) {
                case D.traffic.organic:
                  return (
                    t &&
                    g(t) &&
                    (function (e) {
                      var t = new RegExp(
                          '^(?:.*\\.)?' +
                            S.escapeRegexp('yandex') +
                            '\\..{2,9}$',
                        ),
                        r = new RegExp('.*' + S.escapeRegexp('text') + '=.*'),
                        n = new RegExp(
                          '^(?:www\\.)?' +
                            S.escapeRegexp('google') +
                            '\\..{2,9}$',
                        )
                      {
                        if (
                          H.parse(e).query &&
                          H.parse(e).host.match(t) &&
                          H.parse(e).query.match(r)
                        )
                          return (a = 'yandex')
                        if (H.parse(e).host.match(n)) return (a = 'google')
                        if (H.parse(e).query)
                          for (var s = 0; s < o.organics.length; s++) {
                            if (
                              H.parse(e).host.match(
                                new RegExp(
                                  '^(?:.*\\.)?' +
                                    S.escapeRegexp(o.organics[s].host) +
                                    '$',
                                  'i',
                                ),
                              ) &&
                              H.parse(e).query.match(
                                new RegExp(
                                  '.*' +
                                    S.escapeRegexp(o.organics[s].param) +
                                    '=.*',
                                  'i',
                                ),
                              )
                            )
                              return (
                                (a =
                                  o.organics[s].display || o.organics[s].host),
                                1
                              )
                            if (s + 1 === o.organics.length) return
                          }
                      }
                    })(t)
                  )
                case D.traffic.referral:
                  return (
                    t &&
                    g(t) &&
                    (function (e) {
                      {
                        if (!(0 < o.referrals.length))
                          return (a = H.getHost(e)), 1
                        for (var t = 0; t < o.referrals.length; t++) {
                          if (
                            H.parse(e).host.match(
                              new RegExp(
                                '^(?:.*\\.)?' +
                                  S.escapeRegexp(o.referrals[t].host) +
                                  '$',
                                'i',
                              ),
                            )
                          )
                            return (
                              (a =
                                o.referrals[t].display || o.referrals[t].host),
                              (r = o.referrals[t].medium || D.referer.referral),
                              1
                            )
                          if (t + 1 === o.referrals.length)
                            return (a = H.getHost(e)), 1
                        }
                      }
                    })(t)
                  )
                default:
                  return
              }
            }
            function g(e) {
              var t
              return o.domain
                ? p
                  ? H.getHost(e) !== H.getHost(u)
                  : ((t = new RegExp(
                      '^(?:.*\\.)?' + S.escapeRegexp(u) + '$',
                      'i',
                    )),
                    !H.getHost(e).match(t))
                : H.getHost(e) !== H.getHost(document.location.href)
            }
            function d() {
              R.set(
                q.containers.current_extra,
                q.pack.extra(o.timezone_offset),
                m,
                u,
                p,
              ),
                R.get(q.containers.first_extra) ||
                  R.set(
                    q.containers.first_extra,
                    q.pack.extra(o.timezone_offset),
                    m,
                    u,
                    p,
                  )
            }
            function h(e) {
              var t = (t = R.get('sbjs_initial_visit')) ? parseInt(t, 10) : null
              if (t) return (e = 60 * e * 1e3), Date.now() - t > e
            }
            function _(e) {
              if (e)
                for (var t = e.split(q.delimiter), r = 0; r < t.length; r++)
                  if (t[r].startsWith(q.aliases.main.type + '='))
                    return t[r].split('=')[1]
              return null
            }
            function y(e) {
              return 'typein' === e
            }
            T.go(m, u, p),
              h(m) &&
                [
                  q.containers.current,
                  q.containers.current_extra,
                  q.containers.first,
                  q.containers.first_extra,
                  q.containers.session,
                  q.containers.udata,
                  q.containers.promocode,
                  q.service.migrations,
                  'sbjs_initial_visit',
                ].forEach(function (e) {
                  R.destroy(e)
                }),
              (e = o.session_length)
            var v,
              x,
              b =
                !!R.get(q.containers.session) &&
                !!(b = R.get('sbjs_session_start')) &&
                ((e = 60 * e * 1e3), Date.now() - parseInt(b, 10) <= e),
              e = R.get(q.containers.current),
              w = _(e),
              k =
                c.utm_source ||
                c.utm_medium ||
                c.utm_campaign ||
                c.utm_content ||
                c.utm_term ||
                c.gclid ||
                c.yclid ||
                c[o.campaign_param] ||
                c[o.term_param] ||
                c[o.content_param]
                  ? (d(), l(D.traffic.utm))
                  : f(D.traffic.organic)
                  ? (d(), l(D.traffic.organic))
                  : !R.get(q.containers.session) && f(D.traffic.referral)
                  ? (d(), l(D.traffic.referral))
                  : (d(), l(D.traffic.typein)),
              I = _(k),
              j = !1
            return (
              I && !y(I)
                ? (w !== D.traffic.typein &&
                    w &&
                    w !== D.traffic.typein &&
                    (b || h(m))) ||
                  (j = !0)
                : I && y(I) && ((e && !h(m)) || (j = !0)),
              j &&
                (R.set(q.containers.current, k, m, u, p), y(I)) &&
                ((w = Date.now().toString()),
                R.set('sbjs_initial_visit', w, m, u, p)),
              R.get(q.containers.first) ||
                R.set(q.containers.first, R.get(q.containers.current), m, u, p),
              (v = R.get(q.containers.udata)
                ? ((v =
                    parseInt(
                      R.parse(q.containers.udata)[R.unsbjs(q.containers.udata)][
                        q.aliases.udata.visits
                      ],
                    ) || 1),
                  (v = R.get(q.containers.session) ? v : v + 1),
                  q.pack.user(v, o.user_ip))
                : q.pack.user((v = 1), o.user_ip)),
              R.set(q.containers.udata, v, m, u, p),
              R.get(q.containers.session)
                ? ((x = R.parse(q.containers.session)[
                    R.unsbjs(q.containers.session)
                  ][q.aliases.session.pages_seen]),
                  (x = parseInt(x) || 1),
                  R.set(
                    q.containers.session,
                    q.pack.session((x += 1)),
                    o.session_length,
                    u,
                    p,
                  ))
                : (R.set(
                    q.containers.session,
                    q.pack.session((x = 1)),
                    o.session_length,
                    u,
                    p,
                  ),
                  R.set(
                    'sbjs_session_start',
                    Date.now().toString(),
                    o.session_length,
                    u,
                    p,
                  )),
              o.promocode &&
                !R.get(q.containers.promocode) &&
                R.set(
                  q.containers.promocode,
                  q.pack.promo(o.promocode),
                  m,
                  u,
                  p,
                ),
              R.parse(q.containers)
            )
          }
        },
        {
          './data': 2,
          './helpers/cookies': 3,
          './helpers/uri': 4,
          './helpers/utils': 5,
          './migrations': 7,
          './params': 8,
          './terms': 9,
        },
      ],
      7: [
        function (e, t, r) {
          'use strict'
          var c = e('./data'),
            u = e('./helpers/cookies')
          t.exports = {
            go: function (e, t, r) {
              var n = this.migrations,
                s = { l: e, d: t, i: r }
              if (u.get(c.containers.first) || u.get(c.service.migrations)) {
                if (!u.get(c.service.migrations))
                  for (i = 0; i < n.length; i++) n[i].go(n[i].id, s)
              } else {
                for (var a = [], i = 0; i < n.length; i++) a.push(n[i].id)
                var o = ''
                for (i = 0; i < a.length; i++)
                  (o += a[i] + '=1'), i < a.length - 1 && (o += c.delimiter)
                u.set(c.service.migrations, o, s.l, s.d, s.i)
              }
            },
            migrations: [
              {
                id: '1418474375998',
                version: '1.0.0-beta',
                go: function (t, r) {
                  function e(e, t, r) {
                    return t || r ? e : c.delimiter
                  }
                  var n = t + '=1',
                    t = t + '=0'
                  try {
                    var s,
                      a = []
                    for (s in c.containers)
                      c.containers.hasOwnProperty(s) && a.push(c.containers[s])
                    for (var i, o = 0; o < a.length; o++)
                      u.get(a[o]) &&
                        ((i = u.get(a[o]).replace(/(\|)?\|(\|)?/g, e)),
                        u.destroy(a[o]),
                        u.set(a[o], i, r.l, r.d, r.i))
                    u.get(c.containers.session) &&
                      u.set(
                        c.containers.session,
                        c.pack.session(0),
                        r.l,
                        r.d,
                        r.i,
                      ),
                      u.set(c.service.migrations, n, r.l, r.d, r.i)
                  } catch (e) {
                    u.set(c.service.migrations, t, r.l, r.d, r.i)
                  }
                },
              },
            ],
          }
        },
        { './data': 2, './helpers/cookies': 3 },
      ],
      8: [
        function (e, t, r) {
          'use strict'
          var a = e('./terms'),
            i = e('./helpers/uri')
          t.exports = {
            fetch: function (e) {
              var t = e || {},
                r = {}
              if (
                ((r.lifetime = this.validate.checkFloat(t.lifetime) || 6),
                (r.lifetime = parseInt(30 * r.lifetime * 24 * 60)),
                (r.session_length =
                  this.validate.checkInt(t.session_length) || 30),
                (r.timezone_offset = this.validate.checkInt(t.timezone_offset)),
                (r.campaign_param = t.campaign_param || !1),
                (r.term_param = t.term_param || !1),
                (r.content_param = t.content_param || !1),
                (r.user_ip = t.user_ip || a.none),
                t.promocode
                  ? ((r.promocode = {}),
                    (r.promocode.min = parseInt(t.promocode.min) || 1e5),
                    (r.promocode.max = parseInt(t.promocode.max) || 999999))
                  : (r.promocode = !1),
                t.typein_attributes &&
                t.typein_attributes.source &&
                t.typein_attributes.medium
                  ? ((r.typein_attributes = {}),
                    (r.typein_attributes.source = t.typein_attributes.source),
                    (r.typein_attributes.medium = t.typein_attributes.medium))
                  : (r.typein_attributes = {
                      source: '(direct)',
                      medium: '(none)',
                    }),
                t.domain && this.validate.isString(t.domain)
                  ? (r.domain = { host: t.domain, isolate: !1 })
                  : t.domain && t.domain.host
                  ? (r.domain = t.domain)
                  : (r.domain = {
                      host: i.getHost(document.location.hostname),
                      isolate: !1,
                    }),
                (r.referrals = []),
                t.referrals && 0 < t.referrals.length)
              )
                for (var n = 0; n < t.referrals.length; n++)
                  t.referrals[n].host && r.referrals.push(t.referrals[n])
              if (((r.organics = []), t.organics && 0 < t.organics.length))
                for (var s = 0; s < t.organics.length; s++)
                  t.organics[s].host &&
                    t.organics[s].param &&
                    r.organics.push(t.organics[s])
              return (
                r.organics.push({
                  host: 'bing.com',
                  param: 'q',
                  display: 'bing',
                }),
                r.organics.push({
                  host: 'yahoo.com',
                  param: 'p',
                  display: 'yahoo',
                }),
                r.organics.push({
                  host: 'about.com',
                  param: 'q',
                  display: 'about',
                }),
                r.organics.push({
                  host: 'aol.com',
                  param: 'q',
                  display: 'aol',
                }),
                r.organics.push({
                  host: 'ask.com',
                  param: 'q',
                  display: 'ask',
                }),
                r.organics.push({
                  host: 'globososo.com',
                  param: 'q',
                  display: 'globo',
                }),
                r.organics.push({
                  host: 'go.mail.ru',
                  param: 'q',
                  display: 'go.mail.ru',
                }),
                r.organics.push({
                  host: 'rambler.ru',
                  param: 'query',
                  display: 'rambler',
                }),
                r.organics.push({
                  host: 'tut.by',
                  param: 'query',
                  display: 'tut.by',
                }),
                r.referrals.push({ host: 't.co', display: 'twitter.com' }),
                r.referrals.push({
                  host: 'plus.url.google.com',
                  display: 'plus.google.com',
                }),
                r
              )
            },
            validate: {
              checkFloat: function (e) {
                return !(!e || !this.isNumeric(parseFloat(e))) && parseFloat(e)
              },
              checkInt: function (e) {
                return !(!e || !this.isNumeric(parseInt(e))) && parseInt(e)
              },
              isNumeric: function (e) {
                return !isNaN(e)
              },
              isString: function (e) {
                return '[object String]' === Object.prototype.toString.call(e)
              },
            },
          }
        },
        { './helpers/uri': 4, './terms': 9 },
      ],
      9: [
        function (e, t, r) {
          'use strict'
          t.exports = {
            traffic: {
              utm: 'utm',
              organic: 'organic',
              referral: 'referral',
              typein: 'typein',
            },
            referer: {
              referral: 'referral',
              organic: 'organic',
              social: 'social',
            },
            none: '(none)',
            oops: '(Houston, we have a problem)',
          }
        },
        {},
      ],
    },
    {},
    [1],
  )(1)
})
