import { normalizeNewCatalogProductItems, serialize } from '@/utilities'
export default {
  async GET_TOP_PRODUCTS(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    const set_url = new URLSearchParams(options)
    const response = await this.$nuxt.$axios
      .get(`/api/v1/products/blocks/top?${set_url.toString()}`)
      .catch(e => {
        console.log(e)
      })
    const newProducts = normalizeNewCatalogProductItems(
      response?.data?.products || [],
    )
    const totalItems = response?.data?.meta?.total
    return { newProducts, totalItems }
  },
  updateTopProducts({ startIndex, items, totalSlideCount }) {
    // Step 1: Replace placeholders with the fetched items
    items.forEach((item, index) => {
      this.top_product_list[startIndex + index] = item
    })

    // Step 2: Handle the placeholder adjustment based on the totalSlideCount
    const currentLength = this.top_product_list.length
    const placeholdersNeeded = totalSlideCount - currentLength

    if (placeholdersNeeded > 0) {
      // If more placeholders are needed, add them
      this.top_product_list = [
        ...this.top_product_list,
        ...Array(placeholdersNeeded).fill({ placeholder: true }),
      ]
    } else if (placeholdersNeeded < 0) {
      // If fewer placeholders are needed, remove the excess
      // We only want to remove placeholders, not actual items
      let placeholdersToRemove = -placeholdersNeeded
      for (let i = currentLength - 1; i >= 0 && placeholdersToRemove > 0; i--) {
        if (this.top_product_list[i].placeholder) {
          this.top_product_list.pop() // Remove from the end
          placeholdersToRemove--
        }
      }
    }

    // Ensure the total length matches the totalSlideCount
    this.top_product_list = this.top_product_list.slice(0, totalSlideCount)
  },

  setInitialTopProductPlaceholders(count) {
    if (this.top_product_list.length === 0) {
      this.top_product_list = Array(count).fill({ placeholder: true })
    }
  },
  setTopProducts(items) {
    items.forEach((item, index) => {
      this.top_product_list[index] = item
    })
  },
}
