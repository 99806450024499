import { useUiStore } from '@/stores/ui'
import Vue from 'vue'
import { WHITELISTED_PATHS_ANDROID, WHITELISTED_PATHS_IOS } from '@/constants'
import { useCustomer } from '@/stores/customer'
import sbjs from '@/static/js/sourcebuster.min.js'

sbjs.init({
  session_length: 30,
  lifetime: 1,
})

export default function ({ route, app, store }) {
  // Store the initial route and query
  let currentRoute = route
  let currentQuery = route.query

  const getAppLink = (currentRoute, query) => {
    const params = {}
    Object.keys(query).forEach(key => {
      params[key] = query[key]
    })
    const uiStore = useUiStore()
    uiStore.testRedirectType = sbjs.get.current.typ
    if (sbjs.get.current.src) {
      params.utm_source = `web_${sbjs.get.current.src}`
    }

    params.utm_medium = sbjs.get.current.mdm
    params.utm_campaign = sbjs.get.current.cmp
    params.utm_content = sbjs.get.current.cnt

    if (app.$cookies.get('_ga')) {
      params.utm_term = app.$cookies.get('_ga').replace(/GA\d+\.\d+\./, '')
    }
    if (store.state.auth.loggedIn) {
      params.uid = store?.state?.auth?.user_ext_id
    }
    if (query.gclid) {
      params.gclid = query.gclid
    }
    const queries = new URLSearchParams()
    Object.keys(params).forEach(key => {
      if (params[key]) queries.append(key, params[key])
    })

    const locale = app.i18n.locale
    const isMainPage = currentRoute.name && currentRoute.name.includes('index')
    const localePattern = /^\/[a-z]{2}\//
    const fullPath = currentRoute.path !== '/' ? currentRoute.path : ''
    const path = isMainPage
      ? 'main'
      : locale !== 'az'
      ? fullPath.replace(localePattern, '')
      : fullPath.replace(/^\//, '')
    return `umico://${path}?${queries.toString()}`
  }

  const openNativeApp = async () => {
    const uiStore = useUiStore()
    await Vue.nextTick() // Ensure the DOM and reactivity system are updated

    const originalLink = getAppLink(currentRoute, currentQuery)

    const cookie = app.$cookies.get('_ga')

    uiStore.testRedirectAppUrl = originalLink
    window.location.href = originalLink

    const timeout = setTimeout(() => {
      if (app.$device.isAndroid) {
        uiStore.popupAppMarket.link =
          'https://play.google.com/store/apps/details?id=com.competo.umicoloy'
      } else if (app.$device.isIos) {
        uiStore.popupAppMarket.link =
          'https://apps.apple.com/az/app/com.competo.umicoprod/id1458111389'
      }
      uiStore.popupAppMarket.visible = true
    }, 2000)

    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        clearTimeout(timeout)
        uiStore.popupAppMarket.visible = false
      }
    })
  }

  // TODO remove additional checks after BIRBANK fix

  const pathMatchesWhitelist = (currentPath, whitelistedPaths) => {
    if (
      currentRoute &&
      (currentRoute.fullPath.includes('fromBB') ||
        currentRoute.fullPath.includes('bbpay_user_id') ||
        app.$cookies.get('auth.fromBB'))
    ) {
      return false
    }
    return whitelistedPaths.some(path => {
      const pathRegex = new RegExp(`^${path.replace(/\*/g, '.*')}$`)
      return pathRegex.test(currentPath)
    })
  }
  if (app.$device.isAndroid || app.$device.isIos) {
    Vue.prototype.$openNativeApp = openNativeApp
    const isInitialLoad = !sessionStorage.getItem('initialLoadDone')

    const isWhitelistedPath = app.$device.isAndroid
      ? pathMatchesWhitelist(route.path, WHITELISTED_PATHS_ANDROID)
      : pathMatchesWhitelist(route.path, WHITELISTED_PATHS_IOS)

    if (
      isWhitelistedPath &&
      document.referrer &&
      sbjs.get.current.src !== '(direct)' &&
      isInitialLoad
    ) {
      if (pathMatchesWhitelist(route.path, WHITELISTED_PATHS_IOS)) {
        Vue.nextTick(() => {
          sessionStorage.setItem('initialLoadDone', 'true')
          openNativeApp()
        })
      }
    }
  }

  app.router.afterEach((to, from) => {
    currentRoute = to
    currentQuery = to.query
  })
}
