
import { mapGetters } from 'vuex'
import setDataLayer from '@/mixins/setDataLayer'
import { mapState, storeToRefs } from 'pinia'
import { useMarketStore } from '@/stores/market'
import { useUiStore } from '@/stores/ui'
export default {
  name: 'MPLandingMenu',
  mixins: [setDataLayer],
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
    showSubMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { isProductsMenuOpen } = storeToRefs(uiStore)
    const { loadedMenuCategory } = storeToRefs(marketStore)
    return {
      marketStore,
      isProductsMenuOpen,
      uiStore,
      loadedMenuCategory,
    }
  },
  data() {
    return {
      category: {},
      hover: false,
      isRoot: true,
    }
  },
  async fetch() {
    await this.marketStore.getCategoryShortcuts({ location: 'landing_menu' })
  },
  computed: {
    ...mapState(useMarketStore, ['categoryShortcuts']),

    subMenu() {
      const subMenu = []
      let isRoot = false
      this.marketStore.productCategoriesBreadcrumbs.forEach(breadCrumb => {
        if (!isRoot) {
          if (this.isShortCut(breadCrumb)) {
            isRoot = true
            subMenu.push(breadCrumb)
          }
        } else {
          subMenu.push(breadCrumb)
        }
      })
      return subMenu
    },
  },
  watch: {
    isProductsMenuOpen(val) {
      if (val) {
        this.$emit('controlMenu', false)
      }
    },
    loadedMenuCategory(newVal, oldVal) {
      if (
        newVal &&
        newVal !== oldVal &&
        this.marketStore?.hoveredMenuCategory?.view_mode === 'products' &&
        newVal.category_id !==
          this.marketStore.hoveredMenuCategory?.category_id &&
        newVal.parent_id === this.marketStore.hoveredMenuCategory?.parent_id &&
        this.showMenu
      ) {
        this.marketStore.setProductCategoriesBreadcrumbs(
          this.marketStore.productCategoriesBreadcrumbs.slice(
            0,
            this.marketStore.productCategoriesBreadcrumbs.length - 1,
          ),
        )
      }
    },
  },

  mounted() {
    if (this.$fetchState.error) {
      console.log('Error', this.$fetchState.error)
    }
  },
  methods: {
    toggleTopMenu(val) {
      this.$emit('controlMenu', val)
    },
    async showSubM() {
      this.$emit('controlSubMenu', true)
    },
    smartCategoryHover() {
      this.marketStore.productCategoriesBreadcrumbs = []
    },
    isShortCut(breadCrumb) {
      if (this.showMenu) this.$emit('controlSubMenu', true)
      let isShortCut = false
      this.categoryShortcuts.forEach(shortcut => {
        if (shortcut.category_id === breadCrumb.id) {
          isShortCut = true
        }
      })
      return isShortCut
    },
  },
}
