import { render, staticRenderFns } from "./companies.vue?vue&type=template&id=2eda44fc"
import script from "./companies.vue?vue&type=script&lang=js"
export * from "./companies.vue?vue&type=script&lang=js"
import style0 from "./companies.vue?vue&type=style&index=0&id=2eda44fc&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderAppHeader: require('/usr/src/app/components/Header/AppHeader.vue').default,CommonMainOverlay: require('/usr/src/app/components/Common/MainOverlay.vue').default,LandingFooterBlock: require('/usr/src/app/components/Landing/FooterBlock.vue').default,UIDebugBtn: require('/usr/src/app/components/UI/DebugBtn.vue').default})
