
import { useMarketStore } from '~/stores/market'
import { storeToRefs } from 'pinia'

export default {
  name: 'FavoriteLinkAnonym',
  setup() {
    const marketStore = useMarketStore()
    const { anonFavoritesCount } = storeToRefs(marketStore)
    const { getFavorites } = marketStore
    return {
      marketStore,
      anonFavoritesCount,
      getFavorites,
    }
  },
  async beforeMount() {
    const customerId = this.$auth.$storage.getLocalStorage('customer_id')
    if (customerId) {
      await this.getFavorites()
    }
  },
}
