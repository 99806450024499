
import { useLotteryStore } from '~/stores/lottery'
export default {
  setup() {
    const LotteryStore = useLotteryStore()
    return {
      LotteryStore,
    }
  },
  computed: {
    link() {
      return (
        this.$config.LOTTERY_LANDING +
        (this.$i18n.locale === 'ru' ? 'index_ru.html' : '')
      )
    },
  },
  mounted() {
    if (this.LotteryStore.isLottery === null) {
      this.LotteryStore.GET_LOTTERY()
    }
  },
}
